<template>
    <div id="admin-header">
        <div class="tobbar">
            <h5 class="float-left">{{pageTitle}}</h5>

            <h6 class="float-right logout" @click="logout()"> <b-icon icon="box-arrow-right"></b-icon> Logout </h6>
            <div class="clearfix"></div>
        </div>
        <div class="breadcrumb-bar">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-white">
                    <li class="breadcrumb-item"><router-link to="/admin">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {{pageTitle}}
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdminHeader",
     props: {
        pageTitle: String
    },
    mounted() {

    },

    methods: {
        logout() {
            window.localStorage.removeItem('user');
            this.$router.push('/admin/login');
        }
    }

};
</script>

<style scoped>
#admin-header {
    box-shadow: 0 0px 3px 0 rgb(0 0 0 / 20%);
    background-color: #fff;
}
.tobbar {
    height: 56px;
    padding: 15px;
    border-bottom: 1px solid #d8dbe0;
}
.breadcrumb-bar {
    height: 56px;
}

.breadcrumb-item:not(.active) a {
    color: #ffb313;
}
.logout {cursor: pointer;}
.logout:hover {color: #ffb313;}
</style>