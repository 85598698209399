<template>
    <div>
        <Header />
        <section class="py-5 main-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-5 mb-4 mb-md-0">
                        <div class="card h-100 bg-yellow card-yellow">
                            <div class="card-body">
                                <div>
                                    <h3>New User</h3>
                                    <p>
                                        By creating an account at Auto Websites,
                                        you will be able to use our software.
                                    </p>
                                </div>
                                <router-link
                                    to="/register"
                                    class="btn btn-ab-white d-block w-100"
                                >
                                    Register
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-5">
                        <div class="card card-white">
                            <div class="card-body">
                                <div
                                    class="login-box"
                                >
                                    <h3>Returning User</h3>
                                    <div
                                        class="alert"
                                        v-bind:class="alertBox.class"
                                        v-if="alertBox.message.length > 0"
                                    >
                                        <span>{{ alertBox.message }}</span>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="email">Email</label>
                                        <input
                                            @blur="showEmailError()"
                                            type="email"
                                            id="email"
                                            class="form-control"
                                            v-model="email"
                                        />
                                        <p class="error-message">
                                            {{ emailErrorMessage }}
                                        </p>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="password">Password</label>
                                        <input
                                            @blur="showPasswordError()"
                                            type="password"
                                            id="password"
                                            class="form-control"
                                            v-model="password"
                                        />
                                        <p class="error-message">
                                            {{ passwordErrorMessage }}
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <p class="mt-3 text-right">
                                            <router-link
                                                class="link-blue small"
                                                to="forgot-password">Forgot Password</router-link
                                            >
                                        </p>
                                        <button
                                            class="
                                                btn btn-ab-yellow
                                                d-block
                                                w-100
                                            "
                                            :disabled="isFormValid == false"
                                            @click="ajaxLogin"
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h6 class="copyright">
                        (C) Copyright Auto Websites, Autopilot Internet Income
                        Pte Ltd - All Rights Reserved Worldwide.
                    </h6>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from "../components/Header.vue";
import MD5 from "crypto-js/md5";

import { isValidEmail, isUserSessionAvailable } from "@/helpers/functions.js";
import { ApiProvider } from "../services/ApiProvider";

export default {
    components: { Header },
    data: function () {
        return {
            email: "",
            password: "",

            emailErrorMessage: "",
            passwordErrorMessage: "",

            alertBox: {
                message: "",
                class: "alert-danger",
            },
        };
    },
    mounted() {
        document.title = "Auto Website";
        if (isUserSessionAvailable()) {
            this.$router.push("/welcome");
        }
    },
    computed: {
        isFormValid() {
            var isValidForm = false;
            if (this.email.length == 0) {
                return isValidForm;
            }

            if (!isValidEmail(this.email)) {
                return isValidForm;
            }

            if (this.password.length == 0) {
                return isValidForm;
            }

            isValidForm = true;
            return true;
        },
    },
    watch: {
        email(value) {
            this.alertBox.message = "";
            if (this.email.length == 0) {
                return;
            }

            if (!isValidEmail(this.email)) {
                return;
            }

            this.emailErrorMessage = "";
        },

        password() {
            this.alertBox.message = "";
            if (this.password.length == 0) {
                return;
            }
            this.passwordErrorMessage = "";
        },
    },
    methods: {
        showEmailError() {
            if (this.email.length == 0) {
                this.emailErrorMessage = "Email should not be empty";
                return;
            }

            if (!isValidEmail(this.email)) {
                this.emailErrorMessage = "Enter a valid email address";
                return;
            }
        },

        showPasswordError() {
            if (this.password.length == 0) {
                this.passwordErrorMessage = "Password should not be empty";
            }
        },

        ajaxLogin() {
            var payload = {
                action: "action_login_user",
                email: this.email,
                password: MD5(this.password).toString(),
                user_role: "user",
            };

            var apiProvider = new ApiProvider();
            apiProvider.loginUser(payload).then((response) => {
                if (response.data.success) {
                    window.localStorage.setItem(
                        "user",
                        JSON.stringify(response.data.user_data)
                    );
                    this.$router.push("/welcome");
                } else if (response.data.success == false) {
                    this.alertBox.message = response.data.message;
                }
            });
        }
    },
};
</script>
<style scoped>
.main-section {
    background-color: #0e3666;
    overflow: auto;
    height: 100%;
}

.card {
    border-radius: 1rem;
}

.card-body {
    padding: 3rem 3rem;
}

.card-yellow h3 {
    padding-bottom: 1rem;
    border-bottom: 2px solid #000;
    margin-bottom: 1rem;
}

.card-yellow .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-white h3 {
    padding-bottom: 1rem;
    border-bottom: 2px solid #ffb313;
    margin-bottom: 1rem;
}

.register-link {
    color: var(--bs-body-color);
}
.register-link:hover {
    color: #ffb313;
}
.error-message {
    color: red;
    font-size: 12px;
}

.copyright {
    color: #fff;
    margin-top: 20px;
    text-align: center;
}
@media (min-width: 768px) {
    .main-section {
        height: calc(100vh - 64px);
        min-height: 500px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>