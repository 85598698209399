<template>
    <div id="header">
        <div class="container-fluid py-2">
            <div
                class="d-flex justify-content-between align-items-center w-100"
            >
                <a href="/#/welcome" v-if="isUserLoggedIn"
                    ><img src="@/assets/logo.png" class="img-fluid logo" alt=""
                /></a>
                <a href="/#/" v-if="!isUserLoggedIn"
                    ><img src="@/assets/logo.png" class="img-fluid logo" alt=""
                /></a>

                <div>
                    <a href="https://autowebsitesoftware.com/guide" target="_blank" class="btn btn-ab-yellow float-left">Guide</a>
                    <div class="float-left mt-1" v-if="isUserLoggedIn">
                        <!-- <a href="https://google.com" target="_blank">Guide</a> -->
                        <h6 class="welcome">
                            Welcome, {{ currentUserName }} •
                            <span @click="logout()" class="logout"
                                ><b-icon icon="box-arrow-right"></b-icon> Logout
                            </span>
                        </h6>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    isUserSessionAvailable,
    getCurrentSessionData,
} from "@/helpers/functions.js";
export default {
    data: function () {
        return {
            isUserLoggedIn: false,
            currentUserName: String,
        };
    },
    mounted() {
        if (isUserSessionAvailable()) {
            this.isUserLoggedIn = true;
            var currentUserData = getCurrentSessionData();
            this.currentUserName =
                currentUserData.u_first_name +
                " " +
                currentUserData.u_last_name;
        }
    },
    methods: {
        logout() {
            window.localStorage.removeItem("user");
            this.$router.push("/");
        },
    },
};
</script>

<style scoped>
#header {
    box-shadow: 0 0px 3px 0 rgb(0 0 0 / 20%);
    position: relative;
    z-index: 100;
}
.logo {
    width: 240px;
}
.logout {
    padding: 5px 10px;
    border: 1px solid #0e3666;
    border-radius: 15px;
    color: #0e3666;
}
.logout:hover {
    background-color: #0e3666;
    color: #fff;
    padding: 5px 10px;
    cursor: pointer;
}
.welcome {
    display: inline-block;
    margin-left: 20px;
}
</style>