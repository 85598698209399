<template>
    <div class="page-generate-website">
        <Header />

        <section class="py-5 main-section">
            <div class="container">
                <!-- Start of the Theme Portion -->

                <div class="card">
                    <div class="card-body">
                        <h3 class="title">Theme Portion</h3>
                        <form action="javascript:void(0)">
                            <div class="form-group mb-4">
                                <label for="select-theme" class="mb-2"
                                    ><b>Theme:</b></label
                                >
                                <b-form-select
                                    class="form-control"
                                    id="select-theme"
                                    v-model="cardThemeConfig.inputThemes"
                                    :options="cardThemeConfig.themeList"
                                ></b-form-select>
                                <a
                                    class="btn btn-ab-yellow mt-3"
                                    @click="
                                        onClickPreviewImage(
                                            'theme_image_preview'
                                        )
                                    "
                                >
                                    Theme Preview
                                </a>
                            </div>
                            <div class="form-group mb-4">
                                <label for="" class="mb-2"
                                    ><b>Background:</b></label
                                >
                                <div class="mb-1">
                                    <input
                                        type="radio"
                                        v-model="
                                            cardThemeConfig.defaultBackground
                                        "
                                        name="theme-background"
                                        value="no"
                                        id="theme-background-default"
                                    />
                                    <label
                                        for="theme-background-default"
                                        class="ml-2"
                                        >Default background</label
                                    >
                                </div>
                                <div class="mb-1">
                                    <input
                                        type="radio"
                                        v-model="
                                            cardThemeConfig.defaultBackground
                                        "
                                        value="yes"
                                        name="theme-background"
                                        id="theme-background-custom"
                                    />
                                    <label
                                        for="theme-background-custom"
                                        class="ml-2"
                                    >
                                        Custom background color</label
                                    >
                                    <div>
                                        <span
                                            class="vc-wrapper"
                                            v-if="
                                                cardThemeConfig.defaultBackground ==
                                                'yes'
                                            "
                                        >
                                            <button
                                                @click="
                                                    cardThemeConfig.showColorPicker =
                                                        !cardThemeConfig.showColorPicker
                                                "
                                                class="btn"
                                                style="border: 1px solid #000"
                                                :style="{
                                                    backgroundColor:
                                                        cardThemeConfig.inputBackgroundColor,
                                                    color: cardThemeConfig.forgroundColor,
                                                }"
                                            >
                                                {{
                                                    cardThemeConfig.showColorPicker
                                                        ? "Close Color Picker"
                                                        : "Show Color Picker"
                                                }}
                                            </button>
                                            <Sketch
                                                v-if="
                                                    cardThemeConfig.showColorPicker
                                                "
                                                v-model="
                                                    cardThemeConfig.colorPickerColor
                                                "
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-4">
                                <label for="" class="mb-2"
                                    ><b>Sidebar placement:</b></label
                                >
                                <div class="mb-1">
                                    <input
                                        type="radio"
                                        name="sidebar-placement"
                                        v-model="
                                            cardThemeConfig.inputSidebarPlacement
                                        "
                                        value="left"
                                        id="sidebar-placement-left"
                                    />
                                    <label
                                        for="sidebar-placement-left"
                                        class="ml-2"
                                        >Left</label
                                    >
                                </div>
                                <div class="mb-1">
                                    <input
                                        type="radio"
                                        v-model="
                                            cardThemeConfig.inputSidebarPlacement
                                        "
                                        name="sidebar-placement"
                                        value="right"
                                        id="sidebar-placement-right"
                                    />
                                    <label
                                        for="sidebar-placement-right"
                                        class="ml-2"
                                    >
                                        Right</label
                                    >
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="sidebar-cta-form" class="mb-2"
                                    ><b>Add Sidebar CTA Form:</b></label
                                >
                                <textarea
                                    name=""
                                    id="sidebar-cta-form"
                                    v-model="cardThemeConfig.inputSidebarCTA"
                                    class="form-control"
                                    rows="5"
                                ></textarea>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- End of the Theme Portion -->

                <!-- Start of the Header Portion -->
                <div class="card">
                    <div class="card-body">
                        <h3 class="title">Header Portion</h3>
                        <form action="javascript:void(0)">
                            <div class="form-group mb-4">
                                <label for="blog-title" class="mb-2"
                                    ><b>Blog Title:</b></label
                                >

                                <input
                                    v-model="cardHeaderConfig.inputBlogTitle"
                                    type="text"
                                    class="form-control"
                                    id="blog-title"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label for="tagline" class="mb-2"
                                    ><b>Tagline:</b></label
                                >

                                <input
                                    v-model="cardHeaderConfig.inputBlogTagline"
                                    type="text"
                                    id="tagline"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label for="" class="mb-2"
                                    ><b
                                        >Background image: (image resolution
                                        should be 1950 x 150)
                                    </b></label
                                >

                                <div class="mt-4">
                                    <input
                                        type="radio"
                                        value="from_upload"
                                        v-model="
                                            cardHeaderConfig.inputHeaderImageType
                                        "
                                        id="header-image-from-upload"
                                        name="header-image"
                                    />
                                    <label
                                        for="upload-header-image"
                                        class="mb-2 ml-2"
                                        >Upload your image
                                        <b
                                            >(Max image size 2Mb and support
                                            png, jpg or jpeg only)</b
                                        ></label
                                    >

                                    <div>
                                        <input
                                            @change="uploadHeaderImage()"
                                            type="file"
                                            ref="header_image"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <input
                                        type="radio"
                                        value="from_web"
                                        v-model="
                                            cardHeaderConfig.inputHeaderImageType
                                        "
                                        id="header-image-from-web"
                                        name="header-image"
                                    />
                                    <label
                                        for="header-image-from-web"
                                        class="mb-2 ml-2"
                                        >Image from web</label
                                    >
                                    <div>
                                        <input
                                            type="text"
                                            v-model="
                                                cardHeaderConfig.inputBackgourndImageUrl
                                            "
                                            class="form-control"
                                        />
                                        <button
                                            class="btn btn-ab-yellow mt-3"
                                            @click="
                                                onClickPreviewImage(
                                                    'header_image_preview'
                                                )
                                            "
                                        >
                                            Preview the Image
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End of the Header Portion -->

                <!-- Start of the Product Review Portion -->
                <div class="card">
                    <div class="card-body">
                        <h3 class="title">Product Review Portion</h3>
                        <form action="javascript:void(0)">
                            <div class="form-group">
                                <label for="" class="mb-2"
                                    ><b>Choose a content mode:</b></label
                                >
                                <div class="row">
                                    <div class="col-auto">
                                        <input
                                            type="radio"
                                            v-model="
                                                cardProductReviewConfig.productImageOnly
                                            "
                                            id="product-content-image-only"
                                            value="yes"
                                            name="product-content-mode"
                                        />
                                        <label
                                            for="product-content-image-only"
                                            class="ml-3"
                                            >Image Only</label
                                        >
                                    </div>
                                    <div class="col-auto">
                                        <input
                                            type="radio"
                                            v-model="
                                                cardProductReviewConfig.productImageOnly
                                            "
                                            id="product-content-image-text"
                                            value="no"
                                            name="product-content-mode"
                                        />
                                        <label
                                            for="product-content-image-text"
                                            class="ml-3"
                                            >Image and text</label
                                        >
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        cardProductReviewConfig.productImageOnly ==
                                        'no'
                                    "
                                >
                                    <label for=""><b>Product Text</b></label>
                                    <vue-editor
                                        v-model="
                                            cardProductReviewConfig.inputProductText
                                        "
                                        :editorToolbar="
                                            cardProductReviewConfig.customToolbar
                                        "
                                    ></vue-editor>
                                </div>
                            </div>
                            <div class="form-group mb-4">
                                <label for="" class="mb-2"
                                    ><b
                                        >Background image: (image resolution
                                        should be 950 x 550)</b
                                    ></label
                                >

                                <div class="mt-4">
                                    <input
                                        v-model="
                                            cardProductReviewConfig.inputProductImageType
                                        "
                                        type="radio"
                                        id="upload-product-image"
                                        value="from_upload"
                                        name="product-image"
                                    />

                                    <label
                                        for="upload-product-image"
                                        class="mb-2 ml-2"
                                        >Upload your image
                                        <b
                                            >(Max image size 2Mb and support
                                            png, jpg or jpeg only)</b
                                        ></label
                                    >

                                    <div>
                                        <input
                                            @change="uploadProductImage()"
                                            type="file"
                                            ref="product_image"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <input
                                        type="radio"
                                        v-model="
                                            cardProductReviewConfig.inputProductImageType
                                        "
                                        id="product-image-from-web"
                                        value="from_web"
                                        name="product-image"
                                    />

                                    <label
                                        for="product-image-from-web"
                                        class="mb-2 ml-2"
                                        >Image from web</label
                                    >
                                    <div>
                                        <input
                                            type="text"
                                            v-model="
                                                cardProductReviewConfig.inputProductBackgourndImageUrl
                                            "
                                            class="form-control"
                                        />

                                        <button
                                            class="btn btn-ab-yellow mt-3"
                                            @click="
                                                onClickPreviewImage(
                                                    'product_image_preview'
                                                )
                                            "
                                        >
                                            Preview the Image
                                        </button>

                                        <div class="mt-2">
                                            <label
                                                for="product-url"
                                                class="mb-2 ml-2"
                                                >Product Link</label
                                            >
                                            <input
                                                type="text"
                                                v-model="
                                                    cardProductReviewConfig.inputProductLink
                                                "
                                                class="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End of the Product Review Portion -->
                <!-- Start of the Bottom Banner Portion -->
                <div class="card">
                    <div class="card-body">
                        <h3 class="title">Bottom Banner Portion</h3>
                        <div class="form-group mb-4">
                            <label for="" class="mb-2"
                                ><b
                                    >Background image: (image resolution should
                                    be 1950 x 200)</b
                                ></label
                            >

                            <div class="mt-4">
                                <input
                                    type="radio"
                                    value="from_upload"
                                    v-model="
                                        cardBottomBannerConfig.inputBottomBannerImageType
                                    "
                                    id="bottom-banner-image-from-upload"
                                    name="bottom-banner-image"
                                />
                                <label
                                    for="upload-bottom-banner-image"
                                    class="mb-2 ml-2"
                                    >Upload your image
                                    <b
                                        >(Max image size 2Mb and support png,
                                        jpg or jpeg only)</b
                                    ></label
                                >

                                <div>
                                    <input
                                        @change="uploadBottomBannerImage()"
                                        type="file"
                                        ref="bottom_banner_image"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="mt-4">
                                <input
                                    type="radio"
                                    value="from_web"
                                    v-model="
                                        cardBottomBannerConfig.inputBottomBannerImageType
                                    "
                                    id="bottom-banner-image-from-web"
                                    name="bottom-banner-image"
                                />
                                <label
                                    for="bottom-banner-image-from-web"
                                    class="mb-2 ml-2"
                                    >Image from web</label
                                >
                                <div>
                                    <input
                                        type="text"
                                        v-model="
                                            cardBottomBannerConfig.inputBottomBannerBackgourndImageUrl
                                        "
                                        class="form-control"
                                    />
                                    <button
                                        class="btn btn-ab-yellow mt-3"
                                        @click="
                                            onClickPreviewImage(
                                                'bottom_banner_image_preview'
                                            )
                                        "
                                    >
                                        Preview the Image
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End of the Bottom Banner Portion -->

                <!-- Start of the Popup Portion -->
                <div class="card">
                    <div class="card-body">
                        <h3 class="title">Popup Portion</h3>
                        <div class="form-group mb-4">
                            <label for="popup-text"><b>Popup Text</b></label>
                            <vue-editor
                                id="popup-text"
                                v-model="cardPopupConfig.inputPopupText"
                                :editorToolbar="cardPopupConfig.customToolbar"
                            ></vue-editor>
                        </div>
                        <div class="form-group mb-4">
                            <label for="popup-button-text"
                                ><b>Popup Button Text</b></label
                            >
                            <vue-editor
                                id="popup-button-text"
                                v-model="cardPopupConfig.inputPopupButtonText"
                                :editorToolbar="cardPopupConfig.customToolbar"
                            ></vue-editor>
                        </div>
                        <div class="form-group mb-4">
                            <label for="popup-button-link" class="mb-2 ml-2"
                                ><b>Popup Button Link</b></label
                            >
                            <input
                                id="popup-button-link"
                                type="text"
                                v-model="cardPopupConfig.inputPopupButtonLink"
                                class="form-control"
                            />
                        </div>
                    </div>
                </div>
                <!-- End of the Popup Portion -->

                <div class="card">
                    <div class="card-body">
                        <h3 class="title">Advertising Portion</h3>
                        <form action="javascript:void(0)">
                            <div class="form-group mb-4">
                                <img src="@/assets/logo_clickbank.png" />
                            </div>

                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >Clickbank Affilate ID</label
                                >
                                <input
                                    v-model="
                                        cardAdvertisingConfig.inputClickbankAffiliateId
                                    "
                                    type="text"
                                    class="form-control"
                                />
                            </div>

                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >Select Clickbank product category: (Ctrl +
                                    Left Mouse Button)</label
                                >
                                <b-form-select
                                    v-model="
                                        cardAdvertisingConfig.inputClickbankSelectedProductCat
                                    "
                                    :options="
                                        cardAdvertisingConfig.clickbankProductCats
                                    "
                                    multiple
                                    :select-size="10"
                                ></b-form-select>
                            </div>

                            <div class="form-group mb-4">
                                <img src="@/assets/logo_amazon.png" />
                            </div>

                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >Your Amazon Associates ID:</label
                                >
                                <input
                                    v-model="
                                        cardAdvertisingConfig.inputAmazonAssociateId
                                    "
                                    type="text"
                                    class="form-control"
                                />
                            </div>

                            <b-form-group
                                label="Choose Option"
                                v-slot="{ ariaDescribedby }"
                            >
                                <b-form-radio
                                    v-model="
                                        cardAdvertisingConfig.inputAmazonOption
                                    "
                                    :aria-describedby="ariaDescribedby"
                                    name="some-radios"
                                    value="option-1"
                                    >Option 1 (Show Banner Ad from
                                    Amazon)</b-form-radio
                                >
                                <b-form-radio
                                    v-model="
                                        cardAdvertisingConfig.inputAmazonOption
                                    "
                                    :aria-describedby="ariaDescribedby"
                                    name="some-radios"
                                    value="option-2"
                                    >Option 2 (Show product list based on
                                    category and keyword)</b-form-radio
                                >
                            </b-form-group>

                            <div
                                class="form-group mb-4"
                                v-if="
                                    cardAdvertisingConfig.inputAmazonOption ==
                                    'option-2'
                                "
                            >
                                <label class="mb-2" for=""
                                    >Amazon Product Category</label
                                >
                                <b-form-select
                                    v-model="
                                        cardAdvertisingConfig.inputAmazonProductLine
                                    "
                                    :options="
                                        cardAdvertisingConfig.amazonProductCats
                                    "
                                ></b-form-select>
                            </div>

                            <div
                                class="form-group mb-4"
                                v-if="
                                    cardAdvertisingConfig.inputAmazonOption ==
                                    'option-2'
                                "
                            >
                                <label class="mb-2" for=""
                                    >Advertising keywords:</label
                                >
                                <input
                                    v-model="
                                        cardAdvertisingConfig.inputAmazonAdvertisingKeywords
                                    "
                                    type="text"
                                    class="form-control"
                                />
                            </div>

                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >Keyword for Youtube, Vimeo & Article
                                    <b>(Words are Case Sensetive)</b></label
                                >
                                <input
                                    v-model="cardAdvertisingConfig.inputKeyword"
                                    placeholder="Add comma between words for eg.(Computer, Exercise, Traveling)"
                                    type="text"
                                    class="form-control"
                                />
                            </div>

                            <div class="form-group mb-0">
                                <img src="@/assets/logo_rakuten.jpg" width="100" class="img-fluid" />
                            </div>
                             <div class="form-group mb-2">
                                 <label for="rakuten_ad">Rakuten Banner Ad </label>
                                 <textarea
                                    name=""
                                    id="rakuten_ad"
                                    v-model="cardAdvertisingConfig.inputRakutenAd"
                                    class="form-control"
                                    rows="5"
                                ></textarea>
                             </div>

                             <div class="form-group mb-3">
                                <img src="@/assets/logo_share_a_sale.png" width="100" class="img-fluid" />
                            </div>
                             <div class="form-group mb-2">
                                 <label for="share_a_sale_ad">Share A Sale Banner Ad</label>
                                 <textarea
                                    name=""
                                    id="share_a_sale_ad"
                                    v-model="cardAdvertisingConfig.inputShareASaleAd"
                                    class="form-control"
                                    rows="5"
                                ></textarea>
                             </div>

                             <div class="form-group mb-3">
                                <img src="@/assets/logo_adsense.png" width="200" class="img-fluid" />
                            </div>
                             <div class="form-group mb-4">
                                 <label for="publisher_code">Google AdSense Publisher Script</label>
                                 <textarea
                                    name=""
                                    id="pubisher_code"
                                    v-model="cardAdvertisingConfig.inputAdsensePublisherCode"
                                    class="form-control"
                                    rows="5"
                                ></textarea>
                             </div>

                             <div class="form-group mb-4">
                                 <label for="header_code">Google AdSense Header Ad Unit Script</label>
                                 <textarea
                                    name=""
                                    id="header_code"
                                    v-model="cardAdvertisingConfig.inputAdsenseHeaderAdUnitCode"
                                    class="form-control"
                                    rows="5"
                                ></textarea>
                             </div>

                              <div class="form-group mb-4">
                                 <label for="sidebar_code">Google AdSense Sidebar Ad Unit Script</label>
                                 <textarea
                                    name=""
                                    id="sidebar_code"
                                    v-model="cardAdvertisingConfig.inputAdsenseSidebarAdUnitCode"
                                    class="form-control"
                                    rows="5"
                                ></textarea>
                             </div>

                             <div class="form-group mb-4">
                                 <label for="footer_code">Google AdSense Footer Ad Unit Script</label>
                                 <textarea
                                    name=""
                                    id="footer_code"
                                    v-model="cardAdvertisingConfig.inputAdsenseFooterAdUnitCode"
                                    class="form-control"
                                    rows="5"
                                ></textarea>
                             </div>

                             <div class="form-group mb-4">
                                 <label for="home_page_code">Google AdSense Home Page Ad Unit Script</label>
                                 <textarea
                                    name=""
                                    id="home_page_code"
                                    v-model="cardAdvertisingConfig.inputAdsenseHomePageAdUnitCode"
                                    class="form-control"
                                    rows="5"
                                ></textarea>
                             </div>

                        </form>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h3 class="title">
                            Server Connection Configuration Portion
                        </h3>
                        <div class="">
                            <p>
                                Before you publish your site, please make sure
                                that you have completed the 3 steps below:
                            </p>
                            <ol>
                                <li>
                                    Add the IP “192.254.184.249" to your cPanel
                                    > REMOTE MYSQL
                                </li>
                                <li>
                                    Created a MySQL database and user in your
                                    cPanel > MYSQL DATABASES.
                                </li>
                                <li>
                                    Make sure PHP ZipArchive package is enabled
                                    in your cPanel
                                </li>
                                <li>
                                    Make sure your domain name is PHP 7.3 >
                                    MultiPHP Manager
                                </li>
                            </ol>
                            <p>
                                If you have any question, you can contact our
                                <a target="_blank" href="http://support.internetmarketer.com/support/tickets/new"
                                    >helpdesk</a
                                >.
                            </p>
                        </div>
                        <form action="javascript:void(0)">
                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >Home URL:
                                    <b> (eg: http://trainingdog.org) </b>
                                </label>
                                <input
                                    v-model="cardServerConfig.inputHomeUrl"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2" for="">Admin Email: </label>
                                <input
                                    v-model="cardServerConfig.inputAdminEmail"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >FTP Server:
                                    <b>(eg: ftp.trainingdog.org)</b></label
                                >
                                <input
                                    v-model="cardServerConfig.inputFTPServer"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2" for="">FTP Username:</label>
                                <input
                                    v-model="cardServerConfig.inputFTPUsername"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2" for="">FTP Password:</label>
                                <input
                                    v-model="cardServerConfig.inputFTPPassword"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >FTP Path:
                                    <b>
                                        (eg: /public_html) or (eg:
                                        /public_html/xxx)</b
                                    ></label
                                >
                                <input
                                    v-model="cardServerConfig.inputFTPPath"
                                    type="text"
                                    class="form-control"
                                />
                            </div>

                            <div class="form-group mb-4">
                                <label class="mb-2" for="">MySQL Server:</label>
                                <input
                                    v-model="cardServerConfig.inputMYSQLServer"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >MySQL Username:</label
                                >
                                <input
                                    v-model="
                                        cardServerConfig.inputMYSQLUsername
                                    "
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >MySQL Password:</label
                                >
                                <input
                                    v-model="
                                        cardServerConfig.inputMYSQLPassword
                                    "
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2" for=""
                                    >MySQL Database Name:</label
                                >
                                <input
                                    v-model="
                                        cardServerConfig.inputMYSQLDatabaseName
                                    "
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group">
                                <button
                                    class="btn btn-ab-yellow"
                                    @click="ajaxSaveProject()"
                                >
                                    Save
                                </button>
                                <button
                                    :disabled="disablePublishButton"
                                    class="btn ml-2 btn-primary"
                                    @click="onClickPublish"
                                >
                                    Publish
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

        <b-modal
            id="porject-setup-modal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            :hide-header-close="setupDetail.hideCloseHeader"
            hide-footer
            title="Deploying WordPress to Sever"
        >
            <p class="mb-3">
                <b>Note:</b> Do not close the window till process is finished
            </p>
            <ol class="modal-process-indicator">
                <li
                    :class="value.status"
                    v-for="(value, key) in modalProcessIndicator"
                    :key="key"
                >
                    {{ value.message }}
                </li>
            </ol>

            <div class="table-responsive">
                <table
                    v-if="setupDetail.show == true"
                    class="table table-bordered table-striped"
                >
                    <tbody>
                        <tr>
                            <td>Wordpress Site Url:</td>
                            <td>
                                <a
                                    :href="setupDetail.siteUrl"
                                    target="_blank"
                                    >{{ setupDetail.siteUrl }}</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>Wordpress Email:</td>
                            <td>{{ setupDetail.wordpressEmail }}</td>
                        </tr>
                        <tr>
                            <td>Wordpress Username:</td>
                            <td>{{ setupDetail.wordpressUsername }}</td>
                        </tr>
                        <tr>
                            <td>Wordpress Password:</td>
                            <td>{{ setupDetail.wordperssPassword }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <b>Note:</b> Please change your password after
                                login to Wordpress Admin Panel
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>

        <b-modal
            id="image-preview-modal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            title="Image Preview"
        >
            <img
                v-if="previewType == 'theme_image_preview'"
                src="@/assets/auto_blog_theme.png"
                class="img-fluid"
            />
            <img
                v-if="previewType == 'header_image_preview'"
                :src="previewImageUrl"
                class="img-fluid"
            />
            <img
                v-if="previewType == 'product_image_preview'"
                :src="previewImageUrl"
                class="img-fluid"
            />
            <img
                v-if="previewType == 'bottom_banner_image_preview'"
                :src="previewImageUrl"
                class="img-fluid"
            />
        </b-modal>
    </div>
</template>

<script>
import {
    isValidEmail,
    isUserSessionAvailable,
    getContrastYIQ,
    getCurrentSessionData,
} from "@/helpers/functions.js";
import Header from "../components/Header.vue";
import { ApiProvider } from "../services/ApiProvider";
import { Sketch } from "vue-color";
import lodash from "lodash";
import {
    CLICK_BANK_PRODUCT_CATEGORIES,
    AMAZON_PRODUCT_CATS,
} from "../helpers/constants";
import { VueEditor } from "vue2-editor";
export default {
    components: {
        Header,
        Sketch,
        VueEditor,
    },
    data() {
        return {
            previewImageUrl: String(),
            previewType: String,
            disablePublishButton: false,

            cardThemeConfig: {
                showColorPicker: false,
                defaultBackground: "no",
                colorPickerColor: "#ffffff",
                forgroundColor: "black",

                themeList: [
                    { value: null, text: "Please choose a theme" },
                    { value: "auto_blog", text: "Auto Blog - Default" },
                ],
                inputBackgroundColor: "#ffffff",
                inputSidebarPlacement: "right",
                inputThemes: "auto_blog",
                inputSidebarCTA: String(),
            },

            cardHeaderConfig: {
                inputBlogTitle: String(),
                inputBlogTagline: String(),
                inputShowBlogTagline: false,
                inputBackgroundImage: Object,
                inputBackgourndImageUrl: String(),
                inputHeaderImageType: "from_upload",
            },

            cardProductReviewConfig: {
                productImageOnly: "yes",
                customToolbar: [
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["code-block"],
                ],
                inputProductBackgroundImage: Object,
                inputProductBackgourndImageUrl: String(),
                inputProductImageType: "from_upload",
                inputProductText: String(),
                inputProductLink: String(),
            },

            cardBottomBannerConfig: {
                inputBottomBannerBackgroundImage: Object,
                inputBottomBannerBackgourndImageUrl: String(),
                inputBottomBannerImageType: "from_upload",
            },

            cardPopupConfig: {
                customToolbar: [
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["code-block"],
                ],
                inputPopupText: String(),
                inputPopupButtonText: String(),
                inputPopupButtonLink: String(),
            },

            cardAdvertisingConfig: {
                inputClickbankAffiliateId: String(),
                inputClickbankSelectedProductCat: [],
                clickbankProductCats: CLICK_BANK_PRODUCT_CATEGORIES,

                inputAmazonOption: "option-1",
                inputAmazonAssociateId: String(),
                inputAmazonProductLine: String(),
                inputAmazonAdvertisingKeywords: String(),
                amazonProductCats: AMAZON_PRODUCT_CATS,
                inputKeyword: String(),
                inputRakutenAd: String(),
                inputShareASaleAd: String(),
                inputAdsensePublisherCode: String(),
                inputAdsenseHeaderAdUnitCode: String(),
                inputAdsenseSidebarAdUnitCode: String(),
                inputAdsenseFooterAdUnitCode: String(),
                inputAdsenseHomePageAdUnitCode: String()

            },

            cardServerConfig: {
                admin_user: "aw-admin",
                admin_password: "auto123_$UNLOCK$",
                inputHomeUrl: String(),
                inputAdminEmail: String(),
                inputFTPServer: String(),
                inputFTPUsername: String(),
                inputFTPPassword: String(),
                inputFTPPath: String(),
                inputMYSQLServer: String(),
                inputMYSQLUsername: String(),
                inputMYSQLPassword: String(),
                inputMYSQLDatabaseName: String(),
            },

            modalProcessIndicator: {
                check_ftp: {
                    status: "pending",
                    message: "Verifing for FTP Connection",
                },
                setup_project: {
                    status: "pending",
                    message: "Setting up Wordpress Project",
                },
                setup_database: {
                    status: "pending",
                    message: "Setting up Database",
                },
                finishing_up: { status: "pending", message: "Finishing up" },
            },

            setupDetail: {
                hideCloseHeader: true,
                show: false,
                siteUrl: String(),
                wordpressEmail: String(),
                wordpressUsername: String(),
                wordperssPassword: String(),
            },
            fileUloadProgress: {
                filesNotUploaded: Object,
                totalFiles: 0,
                filesUploaded: [],
                numberFilesNotUploaded: 0,
                numberFilesUploaded: 0,
                progressInPercent: 0,
                fileToUploadInOneStep: 1,
            },
        };
    },
    mounted() {
        document.title = "Create a New Site - Auto Website";
        if (!isUserSessionAvailable()) {
            this.$router.push("/");
        }

        this.cardAdvertisingConfig.inputClickbankSelectedProductCat = [];
        this.cardAdvertisingConfig.inputClickbankAffiliateId = "";
        this.cardAdvertisingConfig.inputAmazonAssociateId = "";
        this.cardAdvertisingConfig.inputAmazonProductLine = "";
        this.cardAdvertisingConfig.inputAmazonAdvertisingKeywords = "";

        this.cardServerConfig.inputHomeUrl = "";
        this.cardServerConfig.inputAdminEmail = "";
        this.cardServerConfig.inputFTPServer = "";
        this.cardServerConfig.inputFTPUsername = "";
        this.cardServerConfig.inputFTPPassword = "";
        this.cardServerConfig.inputFTPPath = "";
        this.cardServerConfig.inputMYSQLServer = "";
        this.cardServerConfig.inputMYSQLUsername = "";
        this.cardServerConfig.inputMYSQLPassword = "";
        this.cardServerConfig.inputMYSQLDatabaseName = "";

        // Defined

        this.cardThemeConfig.selectedValue = "auto_blog";
        this.cardHeaderConfig.inputBlogTitle = "My New Blog";
        this.cardHeaderConfig.inputBlogTagline = "New Blog Setup";

        this.cardAdvertisingConfig.inputClickbankSelectedProductCat = [];
        (this.cardAdvertisingConfig.inputClickbankAffiliateId = ""),
            (this.cardAdvertisingConfig.inputAmazonAssociateId = "");
        this.cardAdvertisingConfig.inputAmazonProductLine = "";
        this.cardAdvertisingConfig.inputAmazonAdvertisingKeywords = "";
        this.cardAdvertisingConfig.inputKeyword = "";

        this.cardServerConfig.inputHomeUrl = "";
        this.cardServerConfig.inputAdminEmail = "";
        this.cardServerConfig.inputFTPServer = "";
        this.cardServerConfig.inputFTPUsername = "";
        this.cardServerConfig.inputFTPPassword = "";
        this.cardServerConfig.inputFTPPath = "";
        this.cardServerConfig.inputMYSQLServer = "";
        this.cardServerConfig.inputMYSQLUsername = "";
        this.cardServerConfig.inputMYSQLPassword = "";
        this.cardServerConfig.inputMYSQLDatabaseName = "";

        // this.cardServerConfig.inputHomeUrl =
        //     "http://localhost/_server_root/autoblog.test-site.com";
        // this.cardServerConfig.inputAdminEmail = "info@akshayparmar.in";
        // this.cardServerConfig.inputFTPServer = "127.0.0.1";
        // this.cardServerConfig.inputFTPUsername = "akshay";
        // this.cardServerConfig.inputFTPPassword = "parmar@123";
        // this.cardServerConfig.inputFTPPath = "/autoblog.test-site.com";
        // this.cardServerConfig.inputMYSQLServer = "localhost";
        // this.cardServerConfig.inputMYSQLUsername = "root";
        // this.cardServerConfig.inputMYSQLPassword = "";
        // this.cardServerConfig.inputMYSQLDatabaseName = "test_auto_blog_site";

        this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
            if (modalId === "porject-setup-modal") {
                var payload = {
                    action: "",

                    theme_wp_theme: this.cardThemeConfig.inputThemes,
                    theme_background: this.cardThemeConfig.inputBackgroundColor,
                    theme_sidebar_placement:
                        this.cardThemeConfig.inputSidebarPlacement,
                    theme_sidebar_cta: this.cardThemeConfig.inputSidebarCTA,

                    header_blog_title: this.cardHeaderConfig.inputBlogTitle,
                    header_blog_tagline: this.cardHeaderConfig.inputBlogTagline,
                    header_blog_show_tagline:
                        this.cardHeaderConfig.inputShowBlogTagline,
                    header_blog_background_image:
                        this.cardHeaderConfig.inputBackgroundImage,
                    header_blog_background_url:
                        this.cardHeaderConfig.inputBackgourndImageUrl,
                    header_image_type:
                        this.cardHeaderConfig.inputHeaderImageType,

                    product_review_background_image:
                        this.cardProductReviewConfig
                            .inputProductBackgroundImage,
                    product_review_background_url:
                        this.cardProductReviewConfig
                            .inputProductBackgourndImageUrl,
                    product_review_image_type:
                        this.cardProductReviewConfig.inputProductImageType,
                    product_review_text:
                        this.cardProductReviewConfig.inputProductText,
                    product_review_link:
                        this.cardProductReviewConfig.inputProductLink,

                    bottom_banner_background_image:
                        this.cardBottomBannerConfig
                            .inputBottomBannerBackgroundImage,
                    bottom_banner_background_url:
                        this.cardBottomBannerConfig
                            .inputBottomBannerBackgourndImageUrl,
                    bottom_banner_image_type:
                        this.cardBottomBannerConfig.inputBottomBannerImageType,

                    popup_text: this.cardPopupConfig.inputPopupText,
                    popup_button_text:
                        this.cardPopupConfig.inputPopupButtonText,
                    popup_button_link:
                        this.cardPopupConfig.inputPopupButtonLink,

                    clickbank_affiliate_id:
                        this.cardAdvertisingConfig.inputClickbankAffiliateId,
                    clickbank_product_categories: lodash.isEmpty(
                        this.cardAdvertisingConfig
                            .inputClickbankSelectedProductCat
                    )
                        ? [""]
                        : this.cardAdvertisingConfig
                              .inputClickbankSelectedProductCat,
                    clickbank_affiliate_advertising_keywords: "",

                    amazon_associate_id:
                        this.cardAdvertisingConfig.inputAmazonAssociateId,
                    amazon_product_line:
                        this.cardAdvertisingConfig.inputAmazonProductLine,
                    amazon_advertising_keywords:
                        this.cardAdvertisingConfig
                            .inputAmazonAdvertisingKeywords,

                    search_keywords: this.cardAdvertisingConfig.inputKeyword,
                    rakuten_ad: this.cardAdvertisingConfig.inputRakutenAd,
                    share_a_sale_ad: this.cardAdvertisingConfig.inputShareASaleAd,
                    adsense_publisher_code: this.cardAdvertisingConfig.inputAdsensePublisherCode,
                    adsense_header_code: this.cardAdvertisingConfig.inputAdsenseHeaderAdUnitCode,
                    adsense_sidebar_code: this.cardAdvertisingConfig.inputAdsenseSidebarAdUnitCode,
                    adsense_footer_code: this.cardAdvertisingConfig.inputAdsenseFooterAdUnitCode,
                    adsense_home_page_code: this.cardAdvertisingConfig.inputAdsenseHomePageAdUnitCode,

                    home_url: this.cardServerConfig.inputHomeUrl,
                    admin_email: this.cardServerConfig.inputAdminEmail,
                    admin_user: this.cardServerConfig.admin_user,
                    //admin_password: generatePassword(10),
                    admin_password: this.cardServerConfig.admin_password,
                    ftp_host: this.cardServerConfig.inputFTPServer,
                    ftp_port: 21,
                    ftp_username: this.cardServerConfig.inputFTPUsername,
                    ftp_password: this.cardServerConfig.inputFTPPassword,
                    ftp_path: this.cardServerConfig.inputFTPPath,
                    mysql_server: this.cardServerConfig.inputMYSQLServer,
                    mysql_username: this.cardServerConfig.inputMYSQLUsername,
                    mysql_password: this.cardServerConfig.inputMYSQLPassword,
                    mysql_database:
                        this.cardServerConfig.inputMYSQLDatabaseName,
                };

                localStorage.setItem("is_project_setup_processing", "yes");
                this.ajaxVerifyFTPConnection(payload);
            }
        });
    },
    watch: {
        "cardProductReviewConfig.productImageOnly": function (value) {
            if (value == "yes") {
                this.cardProductReviewConfig.inputProductText = "";
            }
        },
        "cardThemeConfig.colorPickerColor": function (value) {
            this.cardThemeConfig.inputBackgroundColor = value.hex8;
            this.cardThemeConfig.forgroundColor = getContrastYIQ(value.hex8);
        },
        "cardThemeConfig.defaultBackground": function (value) {
            if (value == "no") {
                this.cardThemeConfig.inputBackgroundColor = "#ffffff";
                this.cardThemeConfig.forgroundColor = "#000";
            }
        },
    },
    methods: {
        onClickPreviewImage(preview_type) {
            if (preview_type == "theme_image_preview") {
                this.previewImageUrl = "";
                this.previewType = preview_type;
                this.$bvModal.show("image-preview-modal");
            } else if (preview_type == "header_image_preview") {
                this.previewImageUrl =
                    this.cardHeaderConfig.inputBackgourndImageUrl;
                this.previewType = preview_type;
                this.$bvModal.show("image-preview-modal");
            } else if (preview_type == "product_image_preview") {
                this.previewImageUrl =
                    this.cardProductReviewConfig.inputProductBackgourndImageUrl;
                this.previewType = preview_type;
                this.$bvModal.show("image-preview-modal");
            } else if (preview_type == "bottom_banner_image_preview") {
                this.previewImageUrl =
                    this.cardBottomBannerConfig.inputBottomBannerBackgourndImageUrl;
                this.previewType = preview_type;
                this.$bvModal.show("image-preview-modal");
            }
        },
        uploadHeaderImage() {
            var file = this.$refs.header_image.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            var currentCompoenent = this;
            reader.onload = function () {
                currentCompoenent.cardHeaderConfig.inputBackgroundImage =
                    reader.result;
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        },
        uploadProductImage() {
            var file = this.$refs.product_image.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            var currentCompoenent = this;
            reader.onload = function () {
                currentCompoenent.cardProductReviewConfig.inputProductBackgroundImage =
                    reader.result;
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        },
        uploadBottomBannerImage() {
            var file = this.$refs.bottom_banner_image.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            var currentCompoenent = this;
            reader.onload = function () {
                currentCompoenent.cardBottomBannerConfig.inputBottomBannerBackgroundImage =
                    reader.result;
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        },

        onClickPublish() {
            this.$bvModal.show("porject-setup-modal");
        },

        ajaxSaveProject(isPublished) {
            var user_id = getCurrentSessionData().u_id;
            var payload = {
                action: "action_add_project",
                user_id: user_id,
                home_url: this.cardServerConfig.inputHomeUrl,
                admin_email: this.cardServerConfig.inputAdminEmail,
                ftp_host: this.cardServerConfig.inputFTPServer,
                ftp_port: 21,
                ftp_username: this.cardServerConfig.inputFTPUsername,
                ftp_password: this.cardServerConfig.inputFTPPassword,
                ftp_path: this.cardServerConfig.inputFTPPath,
                mysql_server: this.cardServerConfig.inputMYSQLServer,
                mysql_username: this.cardServerConfig.inputMYSQLUsername,
                mysql_password: this.cardServerConfig.inputMYSQLPassword,
                mysql_database: this.cardServerConfig.inputMYSQLDatabaseName,
                published: isPublished ? 1 : 0,
            };

            var apiProvider = new ApiProvider();
            apiProvider.sendPost(payload).then((response) => {
                this.$bvToast.toast(response.data.message, {
                    title: `Project Saved`,
                    variant: "success",
                    solid: true,
                });
            });
        },

        ajaxVerifyFTPConnection(payload) {
            (this.modalProcessIndicator.check_ftp.status = "working"),
                (payload.action = "action_verify_ftp");

            var apiProvider = new ApiProvider();
            apiProvider
                .sendPost(payload)
                .then((response) => {
                    if (response.data.success) {
                        this.modalProcessIndicator.check_ftp.status =
                            "verified";
                        this.modalProcessIndicator.check_ftp.message =
                            response.data.message;

                        //this.ajaxVerifySQLConnection(payload);
                        this.ajaxSetupProject(payload);
                    } else {
                        this.modalProcessIndicator.check_ftp.status = "error";
                        this.modalProcessIndicator.check_ftp.message =
                            response.data.message;
                    }
                })
                .catch((response) => {
                    this.modalProcessIndicator.check_ftp.status = "error";
                    this.modalProcessIndicator.check_ftp.message =
                        "Unable to verify FTP";
                });
        },

        ajaxSetupProject(payload) {
            payload.action = "action_upload_wp_zip";

            this.modalProcessIndicator.setup_project.status = "working";

            var apiProvider = new ApiProvider();
            apiProvider
                .sendPost(payload)
                .then((response) => {
                    if (response.data.success) {
                        this.modalProcessIndicator.setup_project.status =
                            "verified";
                        this.modalProcessIndicator.setup_project.message =
                            response.data.message;
                        this.ajaxSetupDatabase(payload);
                    } else {
                        this.modalProcessIndicator.setup_project.status =
                            "error";
                        this.modalProcessIndicator.setup_project.message =
                            response.data.message;
                    }
                })
                .catch((error) => {
                    this.modalProcessIndicator.setup_project.status = "error";
                    this.modalProcessIndicator.setup_project.message =
                        "Unable to setup project";
                });
        },

        ajaxSetupDatabase(payload) {
            payload.action = "action_setup_database";

            this.modalProcessIndicator.setup_database.status = "working";

            var apiProvider = new ApiProvider();
            apiProvider
                .sendPost(payload)
                .then((response) => {
                    if (response.data.success) {
                        this.modalProcessIndicator.setup_database.status =
                            "verified";
                        this.modalProcessIndicator.setup_database.message =
                            response.data.message;

                        this.ajaxSetupFinishUp(payload);
                    } else {
                        this.modalProcessIndicator.setup_database.status =
                            "error";
                        this.modalProcessIndicator.setup_database.message =
                            response.data.message;

                        setTimeout(() => {
                            this.$bvModal.hide("porject-setup-modal");
                        }, 5000);
                    }
                })
                .catch((error) => {
                    payload.action = "action_check_database";
                    apiProvider = new ApiProvider();
                    apiProvider
                        .sendPost(payload)
                        .then((response) => {
                            if (response.data.success) {
                                this.modalProcessIndicator.setup_database.status =
                                    "verified";
                                this.modalProcessIndicator.setup_database.message =
                                    response.data.message;

                                this.ajaxSetupFinishUp(payload);
                            } else {
                                this.modalProcessIndicator.setup_database.status =
                                    "error";
                                this.modalProcessIndicator.setup_database.message =
                                    response.data.message;
                            }
                        })
                        .catch((error) => {
                            this.modalProcessIndicator.setup_database.status =
                                "error";
                            this.modalProcessIndicator.setup_database.message =
                                "Unable to check database";
                        });
                });
        },

        ajaxSetupFinishUp(payload) {
            (this.modalProcessIndicator.finishing_up.status = "working"),
                (payload.action = "action_setup_finish_up");

            var apiProvider = new ApiProvider();
            apiProvider
                .sendPost(payload)
                .then((response) => {
                    if (response.data.success) {
                        this.modalProcessIndicator.finishing_up.status =
                            "verified";
                        this.modalProcessIndicator.finishing_up.message =
                            response.data.message;

                        this.showSetupDetails(payload);
                        this.ajaxSaveProject(true);
                        this.ajaxSendProjectCredsMail(payload);
                    } else {
                        this.modalProcessIndicator.finishing_up.status =
                            "error";
                        this.modalProcessIndicator.finishing_up.message =
                            response.data.message;

                        setTimeout(() => {
                            this.$bvModal.hide("porject-setup-modal");
                        }, 5000);
                    }
                })
                .catch((error) => {
                    this.modalProcessIndicator.finishing_up.status = "error";
                    this.modalProcessIndicator.finishing_up.message =
                        "Unable to finish project";
                });
        },

        ajaxSendProjectCredsMail(payload) {
            payload.action = "action_send_project_cred_mail";

            var apiProvider = new ApiProvider();
            apiProvider.sendPost(payload).then((response) => {
                if (response.data.success) {
                    this.$bvToast.toast(response.data.message, {
                        title: `Project Credential Mail is Sent`,
                        variant: "success",
                        solid: true,
                    });
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: `Unable to Send Project Credntial Mail`,
                        variant: "danger",
                        solid: true,
                    });
                }
            });
        },
        showSetupDetails(payload) {
            this.setupDetail.hideCloseHeader = false;
            this.setupDetail.siteUrl = payload.home_url;
            this.setupDetail.wordpressEmail = payload.admin_email;
            this.setupDetail.wordpressUsername = payload.admin_user;
            this.setupDetail.wordperssPassword = payload.admin_password;
            this.setupDetail.show = true;
            this.disablePublishButton = true;
            localStorage.setItem("is_project_setup_processing", "");
            //this.resetVariables();
        },

        resetVariables() {
            (this.modalProcessIndicator.check_ftp.status = "pending"),
                (this.modalProcessIndicator.check_ftp.message =
                    "Verifing for FTP Connection"),
                (this.modalProcessIndicator.setup_project.status = "pending");
            this.modalProcessIndicator.setup_project.message =
                "Setting up Wordpress Project";

            this.modalProcessIndicator.setup_database.status = "pending";
            this.modalProcessIndicator.setup_database.message =
                "Setting up Database";

            this.modalProcessIndicator.finishing_up.status = "pending";
            this.modalProcessIndicator.finishing_up.message = "Finishing up";
        },
    },
};
</script>

<style scoped>
.main-section {
    overflow: auto;
}
.container {
    max-width: 750px;
}

.hint {
    color: #c3c3c3;
}

.modal-process-indicator li {
    margin-bottom: 15px;
}
.modal-process-indicator .pending {
    color: #c3c3c3;
}
.modal-process-indicator .working {
    color: orange;
    font-weight: bold;
}
.modal-process-indicator .verified {
    color: green;
    font-weight: bold;
}
.modal-process-indicator .error {
    color: red;
    font-weight: bold;
}
.table {
    font-size: 13px;
}
</style>
