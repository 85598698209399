<template>
    <div>
        <Header />
        <section class="py-5 main-section">
            <div class="container">
                <div class="">
                    <div class="p-5">
                        <h3 class="page-title mb-5 text-white text-center">
                            Welcome to use our software!
                        </h3>
                        <div class="row justify-content-center">
                            <div class="col-12 col-lg-8">
                                <div class="card">
                                    <div class="card-body p-5">
                                        <h5 class="mb-5">Auto Website:</h5>
                                        <div class="row">
                                            <div class="col-6 col-lg-6">
                                                <router-link
                                                    to="/generate-website"
                                                >
                                                    <div class="card card-blue">
                                                        <div class="card-body">
                                                            <b-icon
                                                                icon="plus-circle"
                                                            ></b-icon>
                                                            <br />
                                                            <span
                                                                >Create a New
                                                                Site</span
                                                            >
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                            <div class="col-6 col-lg-6">
                                                <router-link
                                                    to="/pre-saved-sites"
                                                >
                                                <div class="card card-blue">
                                                    <div class="card-body">
                                                        <b-icon
                                                            icon="globe"
                                                        ></b-icon>
                                                        <br />
                                                        <span
                                                            >Load Pre Saved
                                                            Site</span
                                                        >
                                                    </div>
                                                </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-12 col-lg-6">
                                <div class="card">
                                    <div class="card-body p-5">
                                        <h5 class="mb-5">Squeeze Site:</h5>
                                        <div class="row">
                                            <div class="col-6 col-lg-6">
                                                <div class="card card-blue">
                                                    <div class="card-body">
                                                        <b-icon
                                                            icon="plus-circle"
                                                        ></b-icon>
                                                        <br />
                                                        <span
                                                            >Create a New
                                                            Site</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-lg-6">
                                                <div class="card card-blue">
                                                    <div class="card-body">
                                                        <b-icon
                                                            icon="globe"
                                                        ></b-icon>
                                                        <br />
                                                        <span
                                                            >Load Pre Saved
                                                            Site</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div>
                    <h6 class="copyright text-white mt-4 text-center">
                        (C) Copyright Auto Websites, Autopilot Internet Income
                        Pte Ltd - All Rights Reserved Worldwide.
                    </h6>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from "../components/Header.vue";
import MD5 from "crypto-js/md5";

import { isValidEmail, isUserSessionAvailable } from "@/helpers/functions.js";
import { ApiProvider } from "../services/ApiProvider";

export default {
    components: { Header },
    data: function () {
        return {};
    },
    mounted() {
        document.title = "Welcome to Auto Website";
        if (!isUserSessionAvailable()) {
            this.$router.push("/");
        }
    },
};
</script>
<style scoped>
.main-section {
    background-color: #0e3666;
    height: calc(100vh - 64px);
}

.card {
    border-radius: 1rem;
}

.card-blue {
    min-width: 150px;
    min-height: 150px;
    border: 0;
    background-color: #ffb313;
    color: #000;
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    transition: all 0.2s linear;
}

.card-blue span {
    display: inline-block;
    margin-top: 0.7rem;
    font-size: 1rem;
}

.card-blue:hover {
    color: #fff;
    transform: scale(1.1);
    cursor: pointer;
    background-color: #0e3666;
}

</style>