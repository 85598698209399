//export const API_URL = "http://localhost/auto-blog/rest-api/admin/rest.php"
// export const API_URL = "http://localhost/wordpress/auto-blog/rest-api/admin/rest.php"
export const API_URL = "https://autowebsitesoftware.com/rest-api/admin/rest.php"
//export const API_URL = "https://autoblog.digitize-info.com/rest-api/admin/rest.php"

export const CLICK_BANK_PRODUCT_CATEGORIES = [
    { value: "Addiction", text: "Addiction" },
    { value: "Admissions", text: "Admissions" },
    { value: "Affiliate Marketing", text: "Affiliate Marketing" },
    { value: "Alternative Energy", text: "Alternative Energy" },
    { value: "Animal Care & Pets", text: "Animal Care & Pets" },
    { value: "Arabic", text: "Arabic" },
    { value: "Art", text: "Art" },
    { value: "Article Marketing", text: "Article Marketing" },
    { value: "Arts & Entertainment", text: "Arts & Entertainment" },
    { value: "Asia", text: "Asia" },
    { value: "Astrology", text: "Astrology" },
    { value: "Auctions", text: "Auctions" },
    { value: "Automotive", text: "Automotive" },
    { value: "Baking", text: "Baking" },
    { value: "Banners", text: "Banners" },
    { value: "Baseball", text: "Baseball" },
    { value: "Basketball", text: "Basketball" },
    { value: "BBQ", text: "BBQ" },
    { value: "Beauty", text: "Beauty" },
    { value: "Betting Systems", text: "Betting Systems" },
    { value: "Blog Marketing", text: "Blog Marketing" },
    { value: "Body Art", text: "Body Art" },
    { value: "Business / Investing", text: "Business / Investing" },
    { value: "Careers, Industries & Professions", text: "Careers, Industries & Professions" },
    { value: "Casino Table Games", text: "Casino Table Games" },
    { value: "Catalogs & Directories", text: "Catalogs & Directories" },
    { value: "Chinese", text: "Chinese" },
    { value: "Coaching", text: "Coaching" },
    { value: "Commodities", text: "Commodities" },
    { value: "Communications", text: "Communications" },
    { value: "Computers / Internet", text: "Computers / Internet" },
    { value: "Conservation & Efficiency", text: "Conservation & Efficiency" },
    { value: "Console Guides & Repairs", text: "Console Guides & Repairs" },
    { value: "Consulting", text: "Consulting" },
    { value: "Consumer Guides", text: "Consumer Guides"},
    { value: "Cooking", text: "Cooking" },
    { value: "Cooking, Food & Wine", text: "Cooking, Food & Wine"},
    { value: "Copywriting", text: "Copywriting" },
    { value: "Cover Letter & Resume Guides", text: "Cover Letter & Resume Guides" },
    { value: "Crafts & Hobbies", text: "Crafts & Hobbies" },
    { value: "Cycling", text: "Cycling" },
    { value: "Dance", text: "Dance" },
    { value: "Databases", text: "Databases" },
    { value: "Dating Guides", text: "Dating Guides" },
    { value: "Debt", text: "Debt" },
    { value: "Dental Health", text: "Dental Health" },
    { value: "Derivatives", text: "Derivatives" },
    { value: "Developer Tools", text: "Developer Tools" },
    { value: "Dietary Supplements", text: "Dietary Supplements" },
    { value: "Diets & Weight Loss", text: "Diets & Weight Loss" },
    { value: "Digital Photos", text: "Digital Photos" },
    { value: "Divorce", text: "Divorce" },
    { value: "Drinks & Beverages", text: "Drinks & Beverages" },
    { value: "Drivers", text: "Drivers" },
    { value: "E-business & E-marketing", text: "E-business & E-marketing" },
    { value: "E-commerce Operations", text: "E-commerce Operations" },
    { value: "Economics", text: "Economics" },
    { value: "Education", text: "Education" },
    { value: "Educational Materials", text: "Educational Materials" },
    { value: "Email", text: "Email" },
    { value: "Email Marketing", text: "Email Marketing" },
    { value: "Employment & Jobs", text: "Employment & Jobs" },
    { value: "English", text: "English" },
    { value: "Entertaining", text: "Entertaining" },
    { value: "Equities & Stocks", text: "Equities & Stocks" },
    { value: "Europe", text: "Europe" },
    { value: "Exercise & Fitness", text: "Exercise & Fitness" },
    { value: "Fashion", text: "Fashion" },
    { value: "Fiction", text: "Fiction" },
    { value: "Film & Television", text: "Film & Television" },
    { value: "Football", text: "Football" },
    { value: "Foreign Exchange", text: "Foreign Exchange" },
    { value: "Foreign Exchange Investing", text: "Foreign Exchange Investing" },
    { value: "French", text: "French" },
    { value: "Games", text: "Games" },
    { value: "Gardening & Horticulture", text: "Gardening & Horticulture" },
    { value: "Gay / Lesbian", text: "Gay / Lesbian" },
    { value: "Genealogy", text: "Genealogy" },
    { value: "General", text: "General" },
    { value: "German", text: "German" },
    { value: "Golf", text: "Golf" },
    { value: "Graphic Design", text: "Graphic Design" },
    { value: "Graphics", text: "Graphics" },
    { value: "Green Products", text: "Green Products" },
    { value: "Hardware", text: "Hardware" },
    { value: "Health & Fitness", text: "Health & Fitness" },
    { value: "Hebrew", text: "Hebrew" },
    { value: "Higher Education", text: "Higher Education" },
    { value: "Home & Garden", text: "Home & Garden" },
    { value: "Homebuying", text: "Homebuying" },
    { value: "Horse Racing", text: "Horse Racing" },
    { value: "How-to & Home Improvements", text: "How-to & Home Improvements" },
    { value: "Hypnosis", text: "Hypnosis" },
    { value: "Individual Sports", text: "Individual Sports" },
    { value: "Interior Design", text: "Interior Design" },
    { value: "International Business", text: "International Business" },
    { value: "Internet Tools", text: "Internet Tools" },
    { value: "Italian", text: "Italian" },
    { value: "Japanese", text: "Japanese" },
    { value: "Job Listings", text: "Job Listings" },
    { value: "Job Search Guides", text: "Job Search Guides" },
    { value: "Job Skills / Training", text: "Job Skills / Training" },
    { value: "K-12", text: "K-12" },
    { value: "Languages", text: "Languages" },
    { value: "Law & Legal Issues", text: "Law & Legal Issues" },
    { value: "Magic", text: "Magic" },
    { value: "Magic Tricks", text: "Magic Tricks" },
    { value: "Management & Leadership", text: "Management & Leadership" },
    { value: "Marketing", text: "Marketing" },
    { value: "Marketing & Sales", text: "Marketing & Sales" },
    { value: "Marriage", text: "Marriage" },
    { value: "Marriage & Relationships", text: "Marriage & Relationships" },
    { value: "Martial Arts", text: "Martial Arts" },
    { value: "Meditation", text: "Meditation" },
    { value: "Mens Health", text: "Mens Health" },
    { value: "Mental Health", text: "Mental Health" },
    { value: "Mobile", text: "Mobile" },
    { value: "Motivational / Transformational", text: "Motivational / Transformational" },
    { value: "Mountaineering", text: "Mountaineering" },
    { value: "MP3 & Audio", text: "MP3 & Audio" },
    { value: "Music", text: "Music" },
    { value: "Networking", text: "Networking" },
    { value: "Niche Marketing", text: "Niche Marketing" },
    { value: "Numerology", text: "Numerology" },
    { value: "Nutrition", text: "Nutrition" },
    { value: "Other", text: "Other" },
    { value: "Other Investment Software", text: "Other Investment Software" },
    { value: "Other Team Sports", text: "Other Team Sports" },
    { value: "Outdoors & Nature", text: "Outdoors & Nature" },
    { value: "Paranormal", text: "Paranormal" },
    { value: "Parenting", text: "Parenting" },
    { value: "Parenting & Families", text: "Parenting & Families" },
    { value: "Pay Per Click Advertising", text: "Pay Per Click Advertising" },
    { value: "Personal Finance", text: "Personal Finance" },
    { value: "Photography", text: "Photography" },
    { value: "Poker", text: "Poker" },
    { value: "Politics / Current Events", text: "Politics / Current Events" },
    { value: "Pregnancy & Childbirth", text: "Pregnancy & Childbirth" },
    { value: "Productivity", text: "Productivity" },
    { value: "Programming", text: "Programming" },
    { value: "Promotion", text: "Promotion" },
    { value: "Psychics", text: "Psychics" },
    { value: "Public Speaking", text: "Public Speaking" },
    { value: "Racket Sports", text: "Racket Sports" },
    { value: "Real Estate", text: "Real Estate" },
    { value: "Recipes", text: "Recipes" },
    { value: "Reference", text: "Reference" },
    { value: "Regional & Intl.", text: "Regional & Intl." },
    { value: "Religion", text: "Religion" },
    { value: "Remedies", text: "Remedies" },
    { value: "Running", text: "Running" },
    { value: "Security", text: "Security" },
    { value: "Self Defense", text: "Self Defense" },
    { value: "Self-Esteem", text: "Self-Esteem" },
    { value: "Self-Help", text: "Self-Help" },
    { value: "SEM & SEO", text: "SEM & SEO" },
    { value: "Sleep and Dreams", text: "Sleep and Dreams" },
    { value: "Small Biz / Entrepreneurship", text: "Small Biz / Entrepreneurship" },
    { value: "Soccer", text: "Soccer" },
    { value: "Social Media Marketing", text: "Social Media Marketing" },
    { value: "Software", text: "Software" },
    { value: "Software & Services", text: "Software & Services" },
    { value: "Spanish", text: "Spanish" },
    { value: "Special Diet", text: "Special Diet" },
    { value: "Special Needs", text: "Special Needs" },
    { value: "Special Occasions", text: "Special Occasions" },
    { value: "Specialty Travel", text: "Specialty Travel" },
    { value: "Spiritual Health", text: "Spiritual Health" },
    { value: "Spirituality, New Age & Alternative Beliefs", text: "Spirituality, New Age & Alternative Beliefs" },
    { value: "Sports", text: "Sports" },
    { value: "Strategy Guides", text: "Strategy Guides" },
    { value: "Strength Training", text: "Strength Training" },
    { value: "Stress Management", text: "Stress Management" },
    { value: "Success", text: "Success" },
    { value: "Survival", text: "Survival" },
    { value: "System Optimization", text: "System Optimization" },
    { value: "Tarot", text: "Tarot" },
    { value: "Test Prep & Study Guides", text: "Test Prep & Study Guides" },
    { value: "The Sciences", text: "The Sciences" },
    { value: "Time Management", text: "Time Management" },
    { value: "Training", text: "Training" },
    { value: "Travel", text: "Travel" },
    { value: "United States", text: "United States" },
    { value: "Utilities", text: "Utilities" },
    { value: "Vegetables / Vegetarian", text: "Vegetables / Vegetarian" },
    { value: "Video", text: "Video" },
    { value: "Video Marketing", text: "Video Marketing" },
    { value: "Water Sports", text: "Water Sports" },
    { value: "Web Design", text: "Web Design" },
    { value: "Web Hosting", text: "Web Hosting" },
    { value: "Web Site Design", text: "Web Site Design" },
    { value: "Weddings", text: "Weddings" },
    { value: "Wine Making", text: "Wine Making" },
    { value: "Winter Sports", text: "Winter Sports" },
    { value: "Witchcraft", text: "Witchcraft" },
    { value: "Womens Health", text: "Womens Health" },
    { value: "Writing", text: "Writing" },
    { value: "Yoga", text: "Yoga" },
];

export const AMAZON_PRODUCT_CATS = [
    { value: '', text: 'Please choose a product line' },
    { value: 'Apparel', text: 'Apparel & Accessories' },
    { value: 'Baby', text: 'Baby' },
    { value: 'Books', text: 'Books' },
    { value: 'PCHardware', text: 'Computers' },
    { value: 'Electronics', text: 'Electronics' },
    { value: 'HealthPersonalCare', text: 'Health & Beauty' },
    { value: 'Jewelry', text: 'Jewelry' },
    { value: 'HomeGarden', text: 'Kitchen & Housewares' },
    { value: 'Luggage', text: 'Luggage' },
    { value: 'DVD', text: 'Movies & TV' },
    { value: 'Music', text: 'Music' },
    { value: 'PetSupplies', text: 'Pet Supplies' },
    { value: 'Shoes', text: 'Shoes' },
    { value: 'SportingGoods', text: 'Sports & Outdoors' },
    { value: 'Toys', text: 'Toys & Games' },
    { value: 'VideoGames', text: 'Video Games' },
    { value: 'Watches', text: 'Watches' }
];