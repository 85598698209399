<template>
    <div id="aw-app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
    components: {},
    beforeCreate() {
        document.title = "Auto Website";
    },
};
</script>

<style>
#aw-app {

}
</style>
