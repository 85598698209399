<template>
    <admin-layout page-title="Admin Settings">
        <b-form @submit="onSubmit" @reset="onReset">
            <b-tabs content-class="mt-3">
                <b-tab title="SMTP Settings" active>
                    <div class="card mb-4">
                        <div class="card-body">
                            <h6>SMTP Settings</h6>

                            <p style="color: #0e3666">
                                Note: This SMTP detail will be used to send mail
                                during Forgot Password Process
                            </p>
                            <hr />

                            <b-form-group
                                id="input-group-1"
                                label="SMTP Host"
                                label-for="input-1"
                                description=""
                            >
                                <b-form-input
                                    id="input-1"
                                    v-model="form.smtpHost"
                                    type="text"
                                    placeholder=""
                                    required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-2"
                                label="SMTP Username:"
                                label-for="input-2"
                            >
                                <b-form-input
                                    id="input-2"
                                    v-model="form.smtpUsername"
                                    type="text"
                                    placeholder="Enter SMTP Username"
                                    required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-3"
                                label="SMTP Password:"
                                label-for="input-3"
                            >
                                <b-form-input
                                    id="input-2"
                                    v-model="form.smtpPassword"
                                    placeholder="Enter SMTP Password"
                                    type="text"
                                    required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-4"
                                label="SMTP Port:"
                                label-for="input-4"
                            >
                                <b-form-input
                                    id="input-4"
                                    v-model="form.smtpPort"
                                    placeholder="Enter SMTP Port"
                                    type="text"
                                    required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-5"
                                label="SMTP Set From Email:"
                                label-for="input-5"
                            >
                                <b-form-input
                                    id="input-5"
                                    v-model="form.setFrom"
                                    placeholder="Enter SMTP Set From Email"
                                    type="email"
                                    required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-6"
                                label="SMTP Set From Title:"
                                label-for="input-6"
                            >
                                <b-form-input
                                    id="input-6"
                                    v-model="form.setFromTitle"
                                    placeholder="Enter SMTP Set From Title"
                                    type="text"
                                    required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-7"
                                label="SMTP Reply To Email:"
                                label-for="input-7"
                            >
                                <b-form-input
                                    id="input-7"
                                    v-model="form.replyToEmail"
                                    placeholder="Enter Reply to Email"
                                    type="email"
                                    required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-8"
                                label="SMTP Reply To Title:"
                                label-for="input-8"
                            >
                                <b-form-input
                                    id="input-8"
                                    v-model="form.replyToTitle"
                                    type="text"
                                    placeholder="Enter Reply to Title"
                                    required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-9"
                                label="SMTP Mail Subject:"
                                label-for="input-9"
                            >
                                <b-form-input
                                    id="input-9"
                                    v-model="form.mailSubject"
                                    placeholder="Enter Mail Subject"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>

            <div class="card mb-4">
                <div class="card-body">
                    <b-button type="submit" class="mr-3" variant="primary"
                        >Submit</b-button
                    >
                    <b-button type="reset" variant="danger">Reset</b-button>
                </div>
            </div>
        </b-form>
    </admin-layout>
</template>

<script>
import { ApiProvider } from "../../services/ApiProvider";
import AdminLayout from "./extends/AdminLayout.vue";

export default {
    name: "AdminSettings",
    components: { AdminLayout },

    data: function () {
        return {
            form: {
                smtpHost: "",
                smtpUsername: "",
                smtpPassword: "",
                smtpPort: "587",
                setFrom: "",
                setFromTitle: "Auto Blog",
                replyToEmail: "",
                replyToTitle: "Auto Blog",
                mailSubject: "Auto Blog Forget Password 6-digit code",
            },
        };
    },
    mounted() {
        var payload = {
            action: "action_get_admin_settings",
        };
        new ApiProvider().sendPost(payload).then((response) => {
            if (response.data.success) {
                this.form.smtpHost = response.data.data.as_smtp_host;
                this.form.smtpUsername = response.data.data.as_smtp_username;
                this.form.smtpPassword = response.data.data.as_smtp_password;
                this.form.smtpPort = response.data.data.as_smtp_port;
                this.form.setFrom = response.data.data.as_set_from;
                this.form.setFromTitle = response.data.data.as_set_from_title;
                this.form.replyToEmail = response.data.data.as_reply_to_email;
                this.form.replyToTitle = response.data.data.as_reply_to_title;
                this.form.mailSubject = response.data.data.as_mail_subject;
            }
        });
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            var payload = {
                action: "action_save_admin_settings",
                as_smtp_host: this.form.smtpHost,
                as_smtp_username: this.form.smtpUsername,
                as_smtp_password: this.form.smtpPassword,
                as_smtp_port: this.form.smtpPort,
                as_set_from: this.form.setFrom,
                as_set_from_title: this.form.setFromTitle,
                as_reply_to_email: this.form.replyToEmail,
                as_reply_to_title: this.form.replyToTitle,
                as_mail_subject: this.form.mailSubject,
            };

            new ApiProvider().sendPost(payload).then((response) => {
                if (response.data.success) {
                    this.$bvToast.toast(response.data.message, {
                        title: `Setting Saved`,
                        variant: "success",
                        solid: true,
                    });
                }
            });
        },
        onReset() {},
    },
};
</script>

<style scoped>
</style>