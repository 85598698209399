import axios from "axios";
import { API_URL } from "../helpers/constants";
const qs = require('qs');


export class ApiProvider {

    constructor() {
        this.axios = axios;
        this.apiUrl = API_URL;
    }

    registerUser(payload) {
        return this.sendPost(payload)
    }

    checkEmailForUniqueness(payload) {
        return this.sendPost(payload)
    }

    loginUser(payload) {
        return this.sendPost(payload);
    }

    getAllUsers(payload) {
        return this.sendPost(payload);
    }

    changeUserStatus(payload) {
        return this.sendPost(payload);
    }

    deleteUser(payload) {
        return this.sendPost(payload);
    }

    sendPost(payload, headers) {
        return this.axios.post(this.apiUrl, qs.stringify(payload), {headers})
    }
    
    
}