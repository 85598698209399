import _ from "lodash";


export function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isUserSessionAvailable() {
    var user = window.localStorage.getItem('user');
    user = JSON.parse(user);

    if(!_.isEmpty(user) && user.u_email.length > 0 && isValidEmail(user.u_email)) {
        return true;
    } else {
        return false;
    }
}

export function getCurrentSessionData() {
    var userJsonString = window.localStorage.getItem('user');

    if(IsJsonString(userJsonString)) {
        var user = window.localStorage.getItem('user');
        return JSON.parse(user);
    } else {
        return {}
    }
   
}

export function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.toString();
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
}

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


export function generatePassword() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}