// Imported Modules
import Vue from 'vue'
import { BootstrapVue, IconsPlugin, TooltipPlugin } from "bootstrap-vue";

// Imported project js files
import router from './router/index.js';

// Imported Components
import App from './App.vue'


// Imported CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/app.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(TooltipPlugin)

Vue.config.productionTip = false


var app = new Vue({
    router : router,
    render: h => h(App),
    created() {
        window.addEventListener('beforeunload', this.handler)
      },
      methods: {
        handler: function (event) {

            if(localStorage.getItem('is_project_setup_processing') == "yes") {
                event.preventDefault();
                event.returnValue = '';
            }
        }
    }
}).$mount('#app')

