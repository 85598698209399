// Imported Modules
import Vue from "vue";
import VueRouter from "vue-router";

// imported components
import AdminLoginPage from "../views/admin/AdminLoginPage.vue";
import AdminRegisterPage from "../views/admin/AdminRegisterPage.vue";
import AdminDashboardPage from "../views/admin/AdminDashboardPage.vue";
import AdminUserManagementPage from "../views/admin/AdminUserManagementPage.vue";
import AdminProjectManagementPage from "../views/admin/AdminProjectManagementPage.vue";
import AdminSettingsPage from "../views/admin/AdminSettingsPage.vue";
import Tester from "../components/Tester.vue";


import NotFound404Page from "../views/NotFound404Page.vue";
import HomePage from "../views/HomePage.vue";
import RegisterPage from "../views/RegisterPage.vue";
import WelcomePage  from "../views/WelcomePage.vue";
import GenerateWebsitePage  from "../views/GenerateWebsitePage.vue";
import PreSavedSitesPage  from "../views/PreSavedSitesPage.vue";
import ForgotPasswordPage  from "../views/ForgotPasswordPage.vue";

Vue.use(VueRouter)

export default new VueRouter({
    
    routes: [
        {
            path: '/',
            component: HomePage
        },
        {
            path: '/register',
            component: RegisterPage
        },
        {
            path: '/welcome',
            component: WelcomePage
        },
        {
            path: '/generate-website',
            component: GenerateWebsitePage
        },
        {
            path: '/pre-saved-sites',
            component: PreSavedSitesPage
        },
        {
            path: '/forgot-password',
            component: ForgotPasswordPage
        },
        {
            path: '/admin',
            redirect: '/admin/login'
        },
        {
            path: '/admin/login',
            component: AdminLoginPage
        },
        {
            path: '/admin/register',
            component: AdminRegisterPage
        },

        {
            path: '/admin/dashboard',
            component: AdminDashboardPage
        },

        {
            path: '/admin/user-management',
            component: AdminUserManagementPage
        },

        {
            path: '/admin/project-management',
            component: AdminProjectManagementPage
        },

        {
            path: '/admin/settings',
            name:'settings',
            component: AdminSettingsPage
        },

        {
            path: '/tester',
            name:'tester',
            component: Tester
        },

        {
            path: '*',
            component: NotFound404Page
        },
    ],
    
})