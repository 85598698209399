var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('section',{staticClass:"py-5 main-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-5"},[_c('div',{staticClass:"card card-white"},[_c('div',{staticClass:"card-body"},[(_vm.currentView == 'FORGOT_PASSWORD_BOX')?_c('div',{staticClass:"frogot-password-box"},[_c('h3',[_vm._v("Forgot Password")]),(
                                        _vm.forgotPassowrdAlertBox.message
                                            .length > 0
                                    )?_c('div',{staticClass:"alert",class:_vm.forgotPassowrdAlertBox.class},[_c('span',[_vm._v(_vm._s(_vm.forgotPassowrdAlertBox.message))])]):_vm._e(),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Enter your email address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forgotPasswordEmail),expression:"forgotPasswordEmail"}],staticClass:"form-control",attrs:{"type":"email","id":"email"},domProps:{"value":(_vm.forgotPasswordEmail)},on:{"blur":function($event){return _vm.showForgotPasswordEmailError()},"input":function($event){if($event.target.composing){ return; }_vm.forgotPasswordEmail=$event.target.value}}}),_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.forgotPasswordEmailErrorMessage)+" ")])]),_c('div',{staticClass:"form-group"},[_c('p',{staticClass:"text-right"},[_c('router-link',{staticClass:"link-blue small",attrs:{"to":"/"}},[_vm._v("Go Back to Login")])],1),_c('button',{staticClass:"\n                                            btn btn-ab-yellow\n                                            d-block\n                                            w-100\n                                        ",attrs:{"disabled":_vm.isForgotPasswordFormValid ==
                                            false},on:{"click":_vm.ajaxForgotPassword}},[_vm._v(" Send Mail ")])])]):_vm._e(),(_vm.currentView == 'SIX_DIGIT_BOX')?_c('div',{staticClass:"six-digit-box"},[_c('h3',[_vm._v("Enter six Digit Code")]),(
                                        _vm.forgotPassowrdAlertBox.message
                                            .length > 0
                                    )?_c('div',{staticClass:"alert",class:_vm.forgotPassowrdAlertBox.class},[_c('span',[_vm._v(_vm._s(_vm.forgotPassowrdAlertBox.message))])]):_vm._e(),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"six-digit-code"}},[_vm._v("Enter Six Digit Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sixDigitCode),expression:"sixDigitCode"}],staticClass:"form-control",attrs:{"type":"number","id":"six-digit-code"},domProps:{"value":(_vm.sixDigitCode)},on:{"blur":function($event){return _vm.showSixDigitCodeError()},"input":function($event){if($event.target.composing){ return; }_vm.sixDigitCode=$event.target.value}}}),_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.sixDigitCodeErrorMessage)+" ")])]),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"\n                                            btn btn-ab-yellow\n                                            d-block\n                                            w-100\n                                        ",attrs:{"disabled":_vm.isSixDigitCodeFormValid == false},on:{"click":_vm.ajaxVerifySixDigitCode}},[_vm._v(" Verify ")])])]):_vm._e(),(_vm.currentView == 'NEW_PASSWORD_BOX')?_c('div',{staticClass:"six-digit-box"},[_c('h3',[_vm._v("Enter New Password")]),(
                                        _vm.forgotPassowrdAlertBox.message
                                            .length > 0
                                    )?_c('div',{staticClass:"alert",class:_vm.forgotPassowrdAlertBox.class},[_c('span',[_vm._v(_vm._s(_vm.forgotPassowrdAlertBox.message))])]):_vm._e(),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",attrs:{"type":"password","id":"password"},domProps:{"value":(_vm.password)},on:{"blur":function($event){return _vm.checkForPasswordValidation()},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.passwordErrorMessage)+" ")])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",attrs:{"type":"password","id":"confirm-password"},domProps:{"value":(_vm.confirmPassword)},on:{"blur":function($event){return _vm.checkForConfirmPasswordValidation()},"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.confirmPasswordErrorMessage)+" ")])]),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"\n                                            btn btn-ab-yellow\n                                            d-block\n                                            w-100\n                                        ",attrs:{"disabled":_vm.isFormValid == false},on:{"click":_vm.ajaxChangePassword}},[_vm._v(" Change Password ")])])]):_vm._e()])])])]),_vm._m(0)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"copyright"},[_vm._v(" (C) Copyright Auto Websites, Autopilot Internet Income Pte Ltd - All Rights Reserved Worldwide. ")])])}]

export { render, staticRenderFns }