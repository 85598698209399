<template>
    <admin-layout page-title="User Management">
        <section class="card mb-4">
            <div class="card-body">
                <h5 class="mb-3">Filter</h5>
                <b-form
                    @submit="onSubmitFilter"
                    @reset="onResetFilter"
                    action="javascript:void(0)"
                >
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-4">
                            <b-form-group
                                label="Filter by Email"
                                label-for="inputFilterEmail"
                            >
                                <b-form-input
                                    id="inputFilterEmail"
                                    v-model="filterForm.inputFilterEmail"
                                    type="text"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-lg-auto">
                            <b-button type="submit" variant="info" class="mt-3"
                                >Search</b-button
                            >
                        </div>
                        <div class="col-12 col-lg-auto">
                            <b-button class="mt-3" type="reset" variant="danger"
                                >Reset</b-button
                            >
                        </div>
                    </div>
                </b-form>
            </div>
        </section>

        <section class="card">
            <div class="card-body">
                <h5 class="mb-3">User List</h5>
                <div class="table-responsive">
                    <table
                        class="table table-striped table-bordered table-hover"
                    >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Email</th>
                                <th>Name</th>
                                <th>Registration Date</th>
                                <th>Role</th>
                                <th style="text-align: center">Status</th>
                                <!-- <th>Edit</th> -->
                                <th style="text-align: center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                             <tr v-if="showNoDataMSG || showLoading">
                                <td colspan="7" class="text-center">
                                    <img
                                        v-if="showLoading"
                                        src="@/assets/loader.svg"
                                        width="50"
                                    />
                                    <p v-if="showNoDataMSG"> No Data Available </p>
                                </td>
                            </tr>
                            <tr v-for="(user, index) in users" :key="user.u_id">
                                <td>{{ index + 1 }}</td>
                                <td>{{ user.u_email }}</td>
                                <td>
                                    {{
                                        user.u_first_name +
                                        " " +
                                        user.u_last_name
                                    }}
                                </td>
                                <td>{{ user.create_date }}</td>
                                <td>{{ user.ur_name }}</td>
                                <td align="center">
                                    <span v-if="user.status == 0"
                                        >Inactive</span
                                    >
                                    <span v-if="user.status == 1">Active</span>
                                </td>
                                <!-- <td>
                                    <button class="btn btn-warning btn-sm">
                                        Edit <br />
                                    </button>
                                </td> -->
                                <td align="center">
                                    <b-button
                                        v-if="user.status == 0"
                                        v-b-tooltip.hover.left
                                        title="User is deactive, click to activate"
                                        class="btn-sm"
                                        @click="changeUserStatus(user.u_id)"
                                        variant="warning"
                                        >Activate</b-button
                                    >

                                    <b-button
                                        v-else-if="user.status == 1"
                                        v-b-tooltip.hover.left
                                        title="User is active, click to deactivate"
                                        class="btn-sm"
                                        @click="changeUserStatus(user.u_id)"
                                        variant="success"
                                        >Deactivate</b-button
                                    >
                                    &nbsp;
                                    <button
                                        class="btn btn-danger btn-sm"
                                        @click="deleteUser(user.u_id)"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <nav>
                    <ul class="pagination">
                        <li
                            class="page-item"
                            :class="{ disabled: currentPage == 1 }"
                        >
                            <a
                                class="page-link"
                                @click="onClickPaginationLink('previous')"
                                href="javascript:void(0)"
                                >Previous</a
                            >
                        </li>

                        <li
                            class="page-item"
                            :class="{ active: index == currentPage }"
                            v-for="index in totalPages"
                            :key="index"
                        >
                            <a
                                class="page-link"
                                @click="onClickPaginationLink(index)"
                                href="javascript:void(0)"
                                >{{ index }}</a
                            >
                        </li>

                        <li
                            class="page-item"
                            :class="{
                                disabled:
                                    totalPages == 1 ||
                                    totalPages == currentPage,
                            }"
                        >
                            <a
                                class="page-link"
                                @click="onClickPaginationLink('next')"
                                href="javascript:void(0)"
                                >Next</a
                            >
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    </admin-layout>
</template>

<script>
import { ApiProvider } from "../../services/ApiProvider";
import AdminLayout from "./extends/AdminLayout.vue";
import lodash from "lodash";

export default {
    name: "AdminUserManagement",
    components: { AdminLayout },
    data: function () {
        return {
            showLoading: true,
            showNoDataMSG: false,
            lodash: lodash,
            currentPage: 1,
            perPageData: 10,
            totalPages: 1,
            //updateInterval: null,
            users: Object,
            filterForm: {
                inputFilterEmail: String(),
            },
        };
    },
    mounted() {
        this.getAllUsers();
    },
    beforeDestroy() {
        clearInterval(this.updateInterval);
    },
    methods: {
        onClickPaginationLink(page) {
            if (page == "previous") {
                this.currentPage -= 1;
            } else if (page == "next") {
                this.currentPage += 1;
            } else {
                this.currentPage = page;
            }

            this.getAllUsers();
        },
        onSubmitFilter() {
           this.getAllUsers('filter') 
        },
        onResetFilter() {
            this.filterForm.inputFilterEmail = "";
            this.getAllUsers()
        },
        changeUserStatus(userId) {
            var payload = {
                action: "action_change_user_status",
                user_id: userId,
            };

            var apiProvider = new ApiProvider();
            apiProvider.getAllUsers(payload).then((response) => {
                if (response.data.success) {
                    this.getAllUsers();
                }
            });
        },

        deleteUser(userId) {
            var payload = {
                action: "action_delete_user",
                user_id: userId,
            };

            var apiProvider = new ApiProvider();
            apiProvider.deleteUser(payload).then((response) => {
                if (response.data.success) {
                    this.getAllUsers();
                }
            });
        },

        getAllUsers(value) {
            this.showNoDataMSG = false;
             this.showLoading = true;
            this.users = Object;
            
            if(value == 'filter') {
                this.currentPage = 1;    
            }

            var payload = {
                action: "action_get_all_users",
                current_page: this.currentPage,
                per_page_data: this.perPageData,
                filter_email: lodash.trim(this.filterForm.inputFilterEmail)
            };

            var apiProvider = new ApiProvider();
            apiProvider.getAllUsers(payload).then((response) => {
                 this.showLoading = false;
                if (response.data.success) {
                    this.users = response.data.user_data;
                    this.totalPages = response.data.total_pages;

                    //this.updateInterval = setTimeout(this.getAllUsers, 5000);
                } else {
                    this.totalPages = 1;
                    this.showNoDataMSG = true;
                }
            });
        },
    },
};
</script>

<style scoped>
</style>