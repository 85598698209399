<template>
    <admin-layout page-title="Dashboard">
        <div class="row">
            <div class="col-md-3 col-lg-2">
                <div class="card border-secondary">
                    <div class="card-header">
                        Active Users
                    </div>

                    <div class="card-body">
                        <div class="text-right">
                            <p class="h3">{{totalActiveUsers}}</p>
                            <router-link to="/admin/user-management">All Users</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-lg-2">
                <div class="card border-secondary">
                    <div class="card-header">
                        Sites
                    </div>

                    <div class="card-body">
                        <div class="text-right">
                            <p class="h3">{{totalProjects}}</p>
                            <router-link to="/admin/project-management">All Sites</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-lg-2">
                <div class="card border-secondary">
                    <div class="card-header">
                        Settings
                    </div>

                    <div class="card-body">
                        <div class="text-right">
                            <p>&nbsp;</p>
                            <router-link to="/admin/settings">Settings</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </admin-layout>
</template>

<script>
import { ApiProvider } from '../../services/ApiProvider';
import AdminLayout from "./extends/AdminLayout.vue";

export default {
    components: { AdminLayout },
    data: function () {
        return {
            dashboard : null,
            totalActiveUsers : 0,
            totalProjects: 0
        };
    },
    mounted() {
        this.getDashboardData();
        
        setInterval(() => {
            this.getDashboardData();
        }, 10000);
    },
    methods: {
        getDashboardData() {
            var payload = {
                action: "action_admin_dashboard",
            };

            new ApiProvider().getAllUsers(payload).then((response) => {
                if (response.data.success) {
                    this.totalActiveUsers = response.data.total_active_users;
                    this.totalProjects = response.data.total_projects;
                }
            });
        }
    }
};
</script>

<style scoped>
</style>