<template>
    <div id="register-page">
        <div class="register-box">
            <img src="@/assets/logo.png" class="img-fluid logo mb-2" alt="" />
            <h5 class="mb-4 text-center">Register</h5>
            <div
                class="alert"
                v-bind:class="alertBox.class"
                v-if="alertBox.message.length > 0"
            >
                <span>{{ alertBox.message }}</span>
            </div>
            <div class="form-group mb-3">
                <label for="email">Email</label>
                <input
                    @blur="checkForEmailValidation()"
                    type="email"
                    id="email"
                    class="form-control"
                    v-model="email"
                />
                <p class="error-message">{{ emailErrorMessage }}</p>
            </div>
            <div class="form-group mb-3">
                <label for="password">Password</label>
                <input
                    @blur="checkForPasswordValidation()"
                    type="password"
                    id="password"
                    class="form-control"
                    v-model="password"
                />
                <p class="error-message">{{ passwordErrorMessage }}</p>
            </div>
            <div class="form-group mb-3">
                <label for="confirm-password">Confirm Password</label>
                <input
                    @blur="checkForConfirmPasswordValidation()"
                    type="password"
                    id="confirm-password"
                    class="form-control"
                    v-model="confirmPassword"
                />
                <p class="error-message">{{ confirmPasswordErrorMessage }}</p>
            </div>
            <div class="form-group mb-3">
                <label for="first-name">First Name</label>
                <input
                    @blur="checkForFirstNameValidation()"
                    type="text"
                    id="first-name"
                    class="form-control"
                    v-model="firstName"
                />
                <p class="error-message">{{ firstNameErrorMessage }}</p>
            </div>
            <div class="form-group mb-3">
                <label for="last-name">Last Name</label>
                <input
                    @blur="checkForLastNameValidation()"
                    type="text"
                    id="last-name"
                    class="form-control"
                    v-model="lastName"
                />
                <p class="error-message">{{ lastNameErrorMessage }}</p>
            </div>
            <div class="form-group mb-3">
                <input
                    type="checkbox"
                    name="agreed"
                    v-model="isAgreed"
                    id="agreed-check-box"
                    class="mr-2"
                />
                <label for="agreed-check-box" class="d-inline">
                    I have read and agree to the Terms & Conditions .</label
                >
            </div>
            <div class="form-group">
                <router-link to="/admin/login" class="float-left register-link"
                    >Back to login
                </router-link>
                <button
                    @click="register()"
                    class="btn btn-ab-yellow float-right"
                    :disabled="isFormValid == false"
                >
                    Register
                </button>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import MD5 from "crypto-js/md5";


import { isValidEmail } from "@/helpers/functions.js";
import { ApiProvider } from "../../services/ApiProvider";


export default {
    name: "AdminRegisterPage",
    data: function () {
        return {
            isAgreed: false,
            isUniqueEmail: false,

            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",

            emailErrorMessage: "",
            passwordErrorMessage: "",
            confirmPasswordErrorMessage: "",
            firstNameErrorMessage: "",
            lastNameErrorMessage: "",

            alertBox: {
                message: "",
                class: "alert-success",
            },
        };
    },
    computed: {
        isFormValid() {
            var isValidForm = false;
            if (this.email.length == 0) {
                return isValidForm;
            }

            if (!isValidEmail(this.email)) {
                return isValidForm;
            }

            if (!this.isUniqueEmail) {
                return isValidForm;
            }

            if (this.password.length == 0) {
                return isValidForm;
            }

            if (this.confirmPassword.length == 0) {
                return isValidForm;
            }

            if (this.confirmPassword.length != this.password.length) {
                return isValidForm;
            }

            if (this.firstName.length == 0) {
                return isValidForm;
            }

            if (this.lastName.length == 0) {
                return isValidForm;
            }

            if (this.isAgreed == false) {
                return isValidForm;
            }

            isValidForm = true;
            return true;
        },
    },
    watch: {
        email(value) {
            if (this.email.length == 0) {
                return;
            }

            if (!isValidEmail(this.email)) {
                return;
            }

            this.emailErrorMessage = "";
            this.isEmailAlreadyExist();
        },
        password() {
            if (this.password.length == 0) {
                return;
            }
            this.passwordErrorMessage = "";
        },

        confirmPassword() {
            if (this.confirmPassword.length == 0) {
                return;
            }

            if (this.confirmPassword != this.password) {
                return;
            }
            this.confirmPasswordErrorMessage = "";
        },
        firstName() {
            if (this.firstName.length == 0) {
                return;
            }
            this.firstNameErrorMessage = "";
        },
        lastName() {
            if (this.lastName.length == 0) {
                return;
            }
            this.lastNameErrorMessage = "";
        },
    },
    methods: {
        checkForEmailValidation() {
            if (this.email.length == 0) {
                this.emailErrorMessage = "Email should not be empty";
                return;
            }

            if (!isValidEmail(this.email)) {
                this.emailErrorMessage = "Enter a valid email address";
                return;
            }

            this.isEmailAlreadyExist();
        },

        checkForPasswordValidation() {
            if (this.password.length == 0) {
                this.passwordErrorMessage = "Password should not be empty";
                return;
            }
        },

        checkForConfirmPasswordValidation() {
            if (this.confirmPassword.length == 0) {
                this.confirmPasswordErrorMessage =
                    "confirm Password should not be empty";
                return;
            }

            if (this.confirmPassword != this.password) {
                this.confirmPasswordErrorMessage =
                    "Password and Confirm Password are not matching";
                return;
            }
        },

        checkForFirstNameValidation() {
            if (this.firstName.length == 0) {
                this.firstNameErrorMessage = "First name should not be empty";
            }
        },

        checkForLastNameValidation() {
            if (this.lastName.length == 0) {
                this.lastNameErrorMessage = "Last name should not be empty";
            }
        },

        isEmailAlreadyExist() {
            var payload = {
                action: "action_email_already_exist",
                email: this.email,
            };

            var apiProvider = new ApiProvider();
            apiProvider.checkEmailForUniqueness(payload).then((response) => {
                if (response.data.success) {
                    this.isUniqueEmail = true;
                } else {
                    this.isUniqueEmail = false;
                    this.emailErrorMessage =
                        "This email address is already in use";
                }
            });
        },

        register() {
            var payload = {
                action: "action_register_user",
                email: this.email,
                password: MD5(this.password).toString(),
                first_name: this.firstName,
                last_name: this.lastName,
                user_role: 'admin'
            };

            var apiProvider = new ApiProvider();
            apiProvider.registerUser(payload).then((response) => {
                if (response.data.success) {
                    this.alertBox.message = response.data.message;
                    setTimeout(() => {
                        this.$router.push("/admin/login");
                    }, 5000);
                }
            });
        },
    },
};
</script>

<style scoped>
#register-page {
    padding: 15px;
    background-color: #d3d3d3;
    min-height: 100vh;
}
.register-box {
    margin-top: 5rem;
    background-color: #fff;
    padding: 20px;
}
.register-link {
    color: var(--bs-body-color);
}
.register-link:hover {
    color: #ffb313;
}
@media (min-width: 768px) {
    #register-page {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .register-box {
        min-width: 400px;
        max-width: 400px;
        margin-top: 0rem;
    }
}
</style>