<template>
    <div class="container-fluid">
        <div style="min-width:992px">
            <div class="row">
                <div class="col-auto p-0">
                    <admin-sidebar />
                </div>
                <div class="col p-0 bg-gray overflow-hidden">
                    <admin-header :page-title="pageTitle" />
                    <div class="page-content">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminHeader from "../../../components/admin/AdminHeader.vue";
import AdminSidebar from "../../../components/admin/AdminSidebar.vue";

import { isUserSessionAvailable } from "@/helpers/functions.js";
export default {
    components: { AdminSidebar, AdminHeader },
    props: {
        pageTitle: String,
    },
    mounted() {
        if (!isUserSessionAvailable()) {
            this.$router.push("/admin/login");
        }
    },
};
</script>

<style scoped>
.bg-gray {
    background-color: #f5f5f5;
}
.page-content {
    padding: 15px;
}
</style>