<template>
    <admin-layout page-title="Project Management">
        <section class="card mb-4">
            <div class="card-body">
                <h5 class="mb-3">Filter</h5>
                <b-form @submit="onSubmitFilter" @reset="onResetFilter" action="javascript:void(0)">
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-4">
                            <b-form-group
                                label="Filter by Admin Email"
                                label-for="inputFilterAdminEmail"
                            >
                                <b-form-input
                                    id="inputFilterAdminEmail"
                                    v-model="filterForm.inputFilterAdminEmail"
                                    type="text"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-lg-auto">
                            <b-button
                                type="submit"
                                variant="info"
                                class="mt-3"
                                >Search</b-button
                            >
                        </div>
                        <div class="col-12 col-lg-auto">
                             <b-button class="mt-3" type="reset" variant="danger">Reset</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </section>
        <section class="card">
            <div class="card-body">
                <h5 class="mb-3">Project List</h5>
                <div class="table-responsive">
                    <table
                        class="table table-striped table-bordered table-hover"
                    >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Project URL</th>
                                <th>Project Admin Name</th>
                                <th>Project Admin Email</th>
                                <th>Project Create Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="showNoDataMsg || showLoading">
                                <td colspan="7" class="text-center">
                                    <img
                                        v-if="showLoading"
                                        src="@/assets/loader.svg"
                                        width="50"
                                    />
                                    <p v-if="showNoDataMsg"> No Data Available </p>
                                </td>
                            </tr>
                            <tr
                                v-for="(project, index) in projects"
                                :key="index"
                            >
                                <td>{{ index + 1 }}</td>
                                <td>{{ project.proj_config_home_url }}</td>
                                <td>
                                    {{
                                        project.u_first_name +
                                        " " +
                                        project.u_last_name
                                    }}
                                </td>
                                <td>{{ project.proj_config_admin_email }}</td>
                                <td>{{ project.create_date }}</td>
                                <td>
                                    <span
                                        class="badge"
                                        :class="
                                            project.proj_published == 0
                                                ? 'badge-warning'
                                                : 'badge-success'
                                        "
                                        >{{
                                            project.proj_published == 0
                                                ? "Not Published"
                                                : "Published"
                                        }}</span
                                    >
                                </td>
                                <td>
                                    <b-dropdown
                                        size="sm"
                                        variant="link"
                                        toggle-class="text-decoration-none"
                                        no-caret
                                    >
                                        <template #button-content>
                                            <button class="btn btn-default">
                                                <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                                            </button>
                                        </template>
                                        <b-dropdown-item
                                            @click="
                                                onClickEditProjectButton(
                                                    project.proj_users_id,
                                                    project.proj_id
                                                )
                                            "
                                            href="javascript:void(0)"
                                            ><span class="text-warning"
                                                >Edit</span
                                            ></b-dropdown-item
                                        >
                                        <b-dropdown-item
                                            @click="
                                                onClickDeleteButton(
                                                    project.proj_users_id,
                                                    project.proj_id
                                                )
                                            "
                                            href="javascript:void(0)"
                                            ><span class="text-danger"
                                                >Delete</span
                                            ></b-dropdown-item
                                        >
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <nav>
                    <ul class="pagination">
                        <li
                            class="page-item"
                            :class="{ disabled: currentPage == 1 }"
                        >
                            <a
                                class="page-link"
                                @click="onClickPaginationLink('previous')"
                                href="javascript:void(0)"
                                >Previous</a
                            >
                        </li>

                        <li
                            class="page-item"
                            :class="{ active: index == currentPage }"
                            v-for="index in totalPages"
                            :key="index"
                        >
                            <a
                                class="page-link"
                                @click="onClickPaginationLink(index)"
                                href="javascript:void(0)"
                                >{{ index }}</a
                            >
                        </li>

                        <li
                            class="page-item"
                            :class="{
                                disabled:
                                    totalPages == 1 ||
                                    totalPages == currentPage,
                            }"
                        >
                            <a
                                class="page-link"
                                @click="onClickPaginationLink('next')"
                                href="javascript:void(0)"
                                >Next</a
                            >
                        </li>
                    </ul>
                </nav>
            </div>
        </section>

        <b-modal
            id="update-project-modal"
            hide-footer
            size="lg"
            :title="`Edit Project - ` + editModalTitle"
        >
            <b-form @submit="onSubmit" action="javascript:void(0)">
                <b-form-group label="Home URL" label-for="inputHomeUrl">
                    <b-form-input
                        id="inputHomeUrl"
                        v-model="form.inputHomeUrl"
                        type="text"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Admin Email" label-for="inputAdminEmail">
                    <b-form-input
                        id="inputAdminEmail"
                        v-model="form.inputAdminEmail"
                        type="text"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="FTP Server" label-for="inputFTPServer">
                    <b-form-input
                        id="inputFTPServer"
                        v-model="form.inputFTPServer"
                        type="text"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="FTP Username" label-for="inputFTPUsername">
                    <b-form-input
                        id="inputFTPUsername"
                        v-model="form.inputFTPUsername"
                        type="text"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="FTP Password" label-for="inputFTPPassword">
                    <b-form-input
                        id="inputFTPPassword"
                        v-model="form.inputFTPPassword"
                        type="text"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="FTP Path" label-for="inputFTPPath">
                    <b-form-input
                        id="inputFTPPath"
                        v-model="form.inputFTPPath"
                        type="text"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="MY SQL Server"
                    label-for="inputMYSQLServer"
                >
                    <b-form-input
                        id="inputMYSQLServer"
                        v-model="form.inputMYSQLServer"
                        type="text"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="My SQL Username"
                    label-for="inputMYSQLUsername"
                >
                    <b-form-input
                        id="inputMYSQLUsername"
                        v-model="form.inputMYSQLUsername"
                        type="text"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="My SQL Password"
                    label-for="inputMYSQLUsername"
                >
                    <b-form-input
                        id="inputMYSQLUsername"
                        v-model="form.inputMYSQLPassword"
                        type="text"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="My SQL Database"
                    label-for="inputMYSQLDatabaseName"
                >
                    <b-form-input
                        id="inputMYSQLDatabaseName"
                        v-model="form.inputMYSQLDatabaseName"
                        type="text"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-button type="submit" variant="primary" class="mr-3"
                    >Update</b-button
                >
            </b-form>
        </b-modal>
    </admin-layout>
</template>

<script>
import { ApiProvider } from "../../services/ApiProvider";
import AdminLayout from "./extends/AdminLayout.vue";
import lodash from "lodash";

export default {
    name: "AdminProjectManagement",
    components: { AdminLayout },
    data: function () {
        return {
            showLoading: true,
            showNoDataMsg: false,
            lodash: lodash,
            projects: Object,
            currentPage: 1,
            perPageData: 10,
            totalPages: 0,
            editModalTitle: String(),
            form: {
                projectID: String(),
                inputHomeUrl: String(),
                inputAdminEmail: String(),
                inputFTPServer: String(),
                inputFTPUsername: String(),
                inputFTPPassword: String(),
                inputFTPPath: String(),
                inputMYSQLServer: String(),
                inputMYSQLUsername: String(),
                inputMYSQLPassword: String(),
                inputMYSQLDatabaseName: String(),
            },

            filterForm: {
                inputFilterAdminEmail: String()
            }
        };
    },

    mounted() {
        this.getAllProjects();
    },

    methods: {
        onClickPaginationLink(page) {
            if (page == "previous") {
                this.currentPage -= 1;
            } else if (page == "next") {
                this.currentPage += 1;
            } else {
                this.currentPage = page;
            }

            this.getAllProjects();
        },
        onSubmitFilter() {
           this.getAllProjects('filter') 
        },
        onResetFilter() {
            this.filterForm.inputFilterAdminEmail = "";
            this.getAllProjects()
        },

        getAllProjects(value) {
            this.showNoDataMsg = false;
            this.showLoading = true;
            this.projects = Object;
            
            if(value == 'filter') {
                this.currentPage = 1;    
            }

            var payload = {
                action: "action_get_all_projects",
                current_page: this.currentPage,
                per_page_data: this.perPageData,
                filter_email: lodash.trim(this.filterForm.inputFilterAdminEmail)
            };

            var apiProvider = new ApiProvider();
            apiProvider.sendPost(payload).then((response) => {
                    this.showLoading = false;
                if (response.data.success) {
                    this.projects = response.data.project_data;
                    this.totalPages = response.data.total_pages;
                } else {
                    this.totalPages = 1;
                    this.showNoDataMsg = true;
                }
            });
        },

        onClickEditProjectButton(userID, projectID) {
            let currentProject = lodash.find(this.projects, {
                proj_id: projectID,
            });

            if (!lodash.isEmpty(currentProject)) {
                this.editModalTitle = currentProject.proj_config_home_url;

                this.form.inputHomeUrl = currentProject.proj_config_home_url;
                this.form.projectID = currentProject.proj_id;
                this.form.inputAdminEmail =
                    currentProject.proj_config_admin_email;
                this.form.inputFTPServer =
                    currentProject.proj_config_ftp_server;
                this.form.inputFTPUsername =
                    currentProject.proj_config_ftp_user_name;
                this.form.inputFTPPassword =
                    currentProject.proj_config_ftp_password;
                this.form.inputFTPPath = currentProject.proj_config_ftp_path;
                this.form.inputMYSQLServer =
                    currentProject.proj_config_mysql_server;
                this.form.inputMYSQLUsername =
                    currentProject.proj_config_mysql_username;
                this.form.inputMYSQLPassword =
                    currentProject.proj_config_mysql_password;
                this.form.inputMYSQLDatabaseName =
                    currentProject.proj_config_mysql_database;

                this.$bvModal.show("update-project-modal");
            }
        },

        onClickDeleteButton(userID, projectID) {
            var payload = {
                action: "action_delete_project",
                project_id: projectID,
                user_id: userID,
            };

            var apiProvider = new ApiProvider();
            apiProvider.sendPost(payload).then((response) => {
                if (response.data.success) {
                    this.$bvToast.toast(response.data.message, {
                        title: "Project Deleted",
                        variant: "success",
                        solid: true,
                    });
                    this.getAllProjects();
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: "Unable to delete project",
                        variant: "danger",
                        solid: true,
                    });
                }
            });
        },
        onSubmit() {
            var payload = {
                action: "action_update_project",
                project_id: this.form.projectID,
                home_url: this.form.inputHomeUrl,
                admin_email: this.form.inputAdminEmail,
                ftp_host: this.form.inputFTPServer,
                ftp_port: 21,
                ftp_username: this.form.inputFTPUsername,
                ftp_password: this.form.inputFTPPassword,
                ftp_path: this.form.inputFTPPath,
                mysql_server: this.form.inputMYSQLServer,
                mysql_username: this.form.inputMYSQLUsername,
                mysql_password: this.form.inputMYSQLPassword,
                mysql_database: this.form.inputMYSQLDatabaseName,
            };

            var apiProvider = new ApiProvider();
            apiProvider.sendPost(payload).then((response) => {
                if (response.data.success) {
                    this.$bvModal.hide("update-project-modal");
                    this.$bvToast.toast(response.data.message, {
                        title: `Project Updated`,
                        variant: "success",
                        solid: true,
                    });
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: "Unable to update project",
                        variant: "danger",
                        solid: true,
                    });
                }
            });
        },
        onReset() {},
    },
};
</script>

<style scoped>
</style>