<template>
<div id="admin-sidebar">
    <img src="@/assets/logo.png" class="img-fluid logo" alt="">
  <ul>
      <li> <router-link to="/admin/dashboard"> <b-icon icon="back"></b-icon> Dashboard</router-link> </li>
      <li> <router-link to="/admin/user-management"><b-icon icon="person"></b-icon> User Management</router-link> </li>
      <li> <router-link to="/admin/project-management"><b-icon icon="journal-bookmark-fill"></b-icon> Project Management</router-link> </li>
      <li> <router-link to="/admin/settings"><b-icon icon="gear"></b-icon> Site Settings</router-link> </li>
  </ul>
</div>
</template>

<script>
export default {
    name: "AdminSideBar"
}
</script>

<style scoped>
    #admin-sidebar {
        min-width: 250px;
        max-width: 250px;
        height: 100%;
        box-shadow: 0 4px 7px 0 rgb(0 0 0 / 20%);
        min-height: 100vh;
    }
    .logo { padding: 15px;}

    ul {
        list-style-type: none;
        margin:0;
        padding: 0;
    }

    a {
        display: block;
        padding: 15px;
        color: #121212;
        text-decoration: none;
        margin-top: -1px;
        font-weight: 500;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }
    a:hover {
        color: #ffb313;
    }
    a > span {
        margin-left: 10px;
    }
</style>