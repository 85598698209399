<template>
    <div id="login-page">
        <div class="login-box">
            <img src="@/assets/logo.png" class="img-fluid logo mb-5" alt="" />
            <div
                class="alert"
                v-bind:class="alertBox.class"
                v-if="alertBox.message.length > 0"
            >
                <span>{{ alertBox.message }}</span>
            </div>
            <div class="form-group mb-3">
                <label for="email">Email</label>
                <input
                    @blur="showEmailError()"
                    type="email"
                    id="email"
                    class="form-control"
                    v-model="email"
                />
                <p class="error-message">{{ emailErrorMessage }}</p>
            </div>
            <div class="form-group mb-3">
                <label for="password">Password</label>
                <input
                    @blur="showPasswordError()"
                    type="password"
                    id="password"
                    class="form-control"
                    v-model="password"
                />
                <p class="error-message">{{ passwordErrorMessage }}</p>
            </div>

            <div class="form-group">
                <router-link
                    to="/admin/register"
                    class="float-left register-link"
                    >Register Now</router-link
                >
                <button
                    class="btn btn-ab-yellow float-right"
                    :disabled="isFormValid == false"
                    @click="login"
                >
                    Login
                </button>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import MD5 from "crypto-js/md5";

import { isValidEmail, isUserSessionAvailable } from "@/helpers/functions.js";
import { ApiProvider } from "../../services/ApiProvider";

export default {
    name: "AdminLoginPage",
    data: function () {
        return {
            email: "",
            password: "",

            emailErrorMessage: "",
            passwordErrorMessage: "",

            alertBox: {
                message: "",
                class: "alert-danger",
            },
        };
    },
    mounted() {
        if(isUserSessionAvailable()) {
            this.$router.push('/admin/dashboard');
        }
    },
    computed: {
        isFormValid() {
            var isValidForm = false;
            if (this.email.length == 0) {
                return isValidForm;
            }

            if (!isValidEmail(this.email)) {
                return isValidForm;
            }

            if (this.password.length == 0) {
                return isValidForm;
            }

            isValidForm = true;
            return true;
        },
    },
    watch: {
        email(value) {
            this.alertBox.message = "";
            if (this.email.length == 0) {
                return;
            }

            if (!isValidEmail(this.email)) {
                return;
            }

            this.emailErrorMessage = "";
        },
        password() {
            this.alertBox.message = "";
            if (this.password.length == 0) {
                return;
            }
            this.passwordErrorMessage = "";
        },
    },
    methods: {
        showEmailError() {
            if (this.email.length == 0) {
                this.emailErrorMessage = "Email should not be empty";
                return;
            }

            if (!isValidEmail(this.email)) {
                this.emailErrorMessage = "Enter a valid email address";
                return;
            }
        },
        showPasswordError() {
            if (this.password.length == 0) {
                this.passwordErrorMessage = "Password should not be empty";
            }
        },

        login() {
            var payload = {
                action: "action_login_user",
                email: this.email,
                password: MD5(this.password).toString(),
                user_role: 'admin'
            };

            var apiProvider = new ApiProvider();
            apiProvider.loginUser(payload).then((response) => {
                if (response.data.success) {
                    window.localStorage.setItem('user', JSON.stringify(response.data.user_data))
                    this.$router.push('/admin/dashboard');
                } else if(response.data.success == false) {
                    this.alertBox.message = response.data.message;
                }
            });
        },
    },
};
</script>

<style scoped>
#login-page {
    padding: 15px;
    background-color: #d3d3d3;
    min-height: 100vh;
}
.login-box {
    margin-top: 5rem;
    background-color: #fff;
    padding: 20px;
}
.register-link {
    color: var(--bs-body-color);
}
.register-link:hover {
    color: #ffb313;
}
.error-message {
    color: red;
    font-size: 12px;
}
@media (min-width: 768px) {
    #login-page {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-box {
        min-width: 400px;
        margin-top: 0rem;
    }
}
</style>