<template>
    <div>
        <Header />
        <section class="py-5 main-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <div class="card card-white">
                            <div class="card-body">
                                <!-- START OF THE FORGET PASSWORD BLOCK -->
                                <div
                                    class="frogot-password-box"
                                    v-if="currentView == 'FORGOT_PASSWORD_BOX'"
                                >
                                    <h3>Forgot Password</h3>
                                    <div
                                        class="alert"
                                        v-bind:class="
                                            forgotPassowrdAlertBox.class
                                        "
                                        v-if="
                                            forgotPassowrdAlertBox.message
                                                .length > 0
                                        "
                                    >
                                        <span>{{
                                            forgotPassowrdAlertBox.message
                                        }}</span>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="email"
                                            >Enter your email address</label
                                        >
                                        <input
                                            @blur="
                                                showForgotPasswordEmailError()
                                            "
                                            type="email"
                                            id="email"
                                            class="form-control"
                                            v-model="forgotPasswordEmail"
                                        />
                                        <p class="error-message">
                                            {{
                                                forgotPasswordEmailErrorMessage
                                            }}
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <p class="text-right">
                                            <router-link
                                                class="link-blue small"
                                                to="/"
                                                >Go Back to Login</router-link
                                            >
                                        </p>
                                        <button
                                            class="
                                                btn btn-ab-yellow
                                                d-block
                                                w-100
                                            "
                                            :disabled="
                                                isForgotPasswordFormValid ==
                                                false
                                            "
                                            @click="ajaxForgotPassword"
                                        >
                                            Send Mail
                                        </button>
                                    </div>
                                </div>
                                <!-- END OF THE FORGET PASSWORD BLOCK -->

                                <!-- START OF THE SIX DIGIT BLOCK -->
                                <div
                                    class="six-digit-box"
                                    v-if="currentView == 'SIX_DIGIT_BOX'"
                                >
                                    <h3>Enter six Digit Code</h3>
                                    <div
                                        class="alert"
                                        v-bind:class="
                                            forgotPassowrdAlertBox.class
                                        "
                                        v-if="
                                            forgotPassowrdAlertBox.message
                                                .length > 0
                                        "
                                    >
                                        <span>{{
                                            forgotPassowrdAlertBox.message
                                        }}</span>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="six-digit-code"
                                            >Enter Six Digit Code</label
                                        >
                                        <input
                                            @blur="showSixDigitCodeError()"
                                            type="number"
                                            id="six-digit-code"
                                            class="form-control"
                                            v-model="sixDigitCode"
                                        />
                                        <p class="error-message">
                                            {{ sixDigitCodeErrorMessage }}
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <button
                                            class="
                                                btn btn-ab-yellow
                                                d-block
                                                w-100
                                            "
                                            :disabled="
                                                isSixDigitCodeFormValid == false
                                            "
                                            @click="ajaxVerifySixDigitCode"
                                        >
                                            Verify
                                        </button>
                                    </div>
                                </div>
                                <!-- END OF THE SIX DIGIT BLOCK -->

                                <!-- START OF THE NEW PASSWORD BLOCK -->
                                <div
                                    class="six-digit-box"
                                    v-if="currentView == 'NEW_PASSWORD_BOX'"
                                >
                                    <h3>Enter New Password</h3>
                                    <div
                                        class="alert"
                                        v-bind:class="
                                            forgotPassowrdAlertBox.class
                                        "
                                        v-if="
                                            forgotPassowrdAlertBox.message
                                                .length > 0
                                        "
                                    >
                                        <span>{{
                                            forgotPassowrdAlertBox.message
                                        }}</span>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="password">Password</label>
                                        <input
                                            @blur="checkForPasswordValidation()"
                                            type="password"
                                            id="password"
                                            class="form-control"
                                            v-model="password"
                                        />
                                        <p class="error-message">
                                            {{ passwordErrorMessage }}
                                        </p>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="confirm-password"
                                            >Confirm Password</label
                                        >
                                        <input
                                            @blur="
                                                checkForConfirmPasswordValidation()
                                            "
                                            type="password"
                                            id="confirm-password"
                                            class="form-control"
                                            v-model="confirmPassword"
                                        />
                                        <p class="error-message">
                                            {{ confirmPasswordErrorMessage }}
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <button
                                            class="
                                                btn btn-ab-yellow
                                                d-block
                                                w-100
                                            "
                                            :disabled="isFormValid == false"
                                            @click="ajaxChangePassword"
                                        >
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h6 class="copyright">
                        (C) Copyright Auto Websites, Autopilot Internet Income
                        Pte Ltd - All Rights Reserved Worldwide.
                    </h6>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from "../components/Header.vue";
import MD5 from "crypto-js/md5";

import { isValidEmail, isUserSessionAvailable } from "@/helpers/functions.js";
import { ApiProvider } from "../services/ApiProvider";

export default {
    components: { Header },
    data: function () {
        return {
            currentView: "FORGOT_PASSWORD_BOX",
            //currentView: "NEW_PASSWORD_BOX",

            forgotPasswordEmail: "",

            sixDigitCode: "",

            password: "",
            confirmPassword: "",

            forgotPasswordEmailErrorMessage: "",

            sixDigitCodeErrorMessage: "",

            passwordErrorMessage: "",
            confirmPasswordErrorMessage: "",

            forgotPassowrdAlertBox: {
                message: "",
                class: "alert-danger",
            },

            sixDigitCodeAlertBox: {
                message: "",
                class: "alert-danger",
            },
        };
    },
    mounted() {
        document.title = "Forgot Password - Auto Website";
        if (isUserSessionAvailable()) {
            this.$router.push("/welcome");
        }
    },
    computed: {
        isForgotPasswordFormValid() {
            var isValidForm = false;
            if (this.forgotPasswordEmail.length == 0) {
                return isValidForm;
            }

            if (!isValidEmail(this.forgotPasswordEmail)) {
                return isValidForm;
            }

            isValidForm = true;
            return true;
        },

        isSixDigitCodeFormValid() {
            var isValidForm = false;
            if (this.sixDigitCode.length != 6) {
                return isValidForm;
            }

            isValidForm = true;
            return true;
        },

        isFormValid() {
            var isValidForm = false;

            if (this.password.length == 0) {
                return isValidForm;
            }

            if (this.confirmPassword.length == 0) {
                return isValidForm;
            }

            if (this.confirmPassword.length != this.password.length) {
                return isValidForm;
            }

            isValidForm = true;
            return true;
        },
    },
    watch: {
        forgotPasswordEmail(value) {
            this.forgotPassowrdAlertBox.message = "";
            if (this.forgotPasswordEmail.length == 0) {
                return;
            }

            if (!isValidEmail(this.forgotPasswordEmail)) {
                return;
            }

            this.forgotPasswordEmailErrorMessage = "";
        },

        sixDigitCode(value) {
            this.sixDigitCodeAlertBox.message = "";
            if (this.sixDigitCode.length != 6) {
                return;
            }

            this.sixDigitCodeErrorMessage = "";
        },

        password() {
            if (this.password.length == 0) {
                return;
            }
            this.passwordErrorMessage = "";
        },

        confirmPassword() {
            if (this.confirmPassword.length == 0) {
                return;
            }

            if (this.confirmPassword != this.password) {
                return;
            }
            this.confirmPasswordErrorMessage = "";
        },
    },
    methods: {
        showForgotPasswordEmailError() {
            if (this.forgotPasswordEmail.length == 0) {
                this.forgotPasswordEmailErrorMessage =
                    "Email should not be empty";
                return;
            }

            if (!isValidEmail(this.forgotPasswordEmail)) {
                this.forgotPasswordEmailErrorMessage =
                    "Enter a valid email address";
                return;
            }
        },

        showSixDigitCodeError() {
            if (this.sixDigitCode.length != 6) {
                this.sixDigitCodeErrorMessage = "Code must contain six digit";
                return;
            }
        },

        showForgotPasswordBox() {
            this.currentView = "FORGOT_PASSWORD_BOX";
        },

        showSixDigitBox() {
            this.currentView = "SIX_DIGIT_BOX";
        },

        checkForPasswordValidation() {
            if (this.password.length == 0) {
                this.passwordErrorMessage = "Password should not be empty";
                return;
            }
        },

        checkForConfirmPasswordValidation() {
            if (this.confirmPassword.length == 0) {
                this.confirmPasswordErrorMessage =
                    "confirm Password should not be empty";
                return;
            }

            if (this.confirmPassword != this.password) {
                this.confirmPasswordErrorMessage =
                    "Password and Confirm Password are not matching";
                return;
            }
        },

        ajaxForgotPassword() {
            var payload = {
                action: "action_forgot_password",
                email: this.forgotPasswordEmail,
            };

            var apiProvider = new ApiProvider();
            apiProvider.sendPost(payload).then((response) => {
                if (response.data.success) {
                    console.log(response.data);
                    this.showSixDigitBox();
                } else if (response.data.success == false) {
                    console.log(response.data);
                }
            });
        },

        ajaxVerifySixDigitCode() {
            var payload = {
                action: "action_verify_six_digit_code",
                digit_code: this.sixDigitCode,
                email: this.forgotPasswordEmail,
            };

            var apiProvider = new ApiProvider();
            apiProvider.sendPost(payload).then((response) => {
                if (response.data.success) {
                    this.currentView = "NEW_PASSWORD_BOX";
                }
            });
        },

        ajaxChangePassword() {
            var payload = {
                action: "action_change_password",
                password: MD5(this.password).toString(),
                email: this.forgotPasswordEmail,
            };

            var apiProvider = new ApiProvider();
            apiProvider.sendPost(payload).then((response) => {
                if (response.data.success) {
                    this.$bvToast.toast("Your Password is change, We will redirect you to Login Page", {
                        title: `Password Changed Succefully`,
                        variant: "success",
                        solid: true,
                    });

                    setTimeout(() => {
                        this.$router.push('/')
                    }, 4000);
                }
            });
        },
    },
};
</script>
<style scoped>
.main-section {
    background-color: #0e3666;
}
.card {
    border-radius: 1rem;
}
.card-body {
    padding: 3rem 3rem;
}

.card-yellow h3 {
    padding-bottom: 1rem;
    border-bottom: 2px solid #000;
    margin-bottom: 1rem;
}

.card-yellow .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-white h3 {
    padding-bottom: 1rem;
    border-bottom: 2px solid #ffb313;
    margin-bottom: 1rem;
}

.register-link {
    color: var(--bs-body-color);
}
.register-link:hover {
    color: #ffb313;
}
.error-message {
    color: red;
    font-size: 12px;
}

.copyright {
    color: #fff;
    margin-top: 20px;
    text-align: center;
}
@media (min-width: 768px) {
    .main-section {
        height: calc(100vh - 64px);
        min-height: 500px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>